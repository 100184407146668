import {
  DISPLAY_JOURNAL,
  HIDE_JOURNAL,
  DISPLAY_FOOTER,
  HIDE_FOOTER
} from "../sagas/types";

const initialState = {
  displayJournal: false,
  displayFooter: true,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case DISPLAY_JOURNAL: {
      return {
        ...state,
        displayJournal: true
      };
    }

    case HIDE_JOURNAL: {
      return {
        ...state,
        displayJournal: false
      };
    }

    case DISPLAY_FOOTER: {
      return {
        ...state,
        displayFooter: true
      };
    }

    case HIDE_FOOTER: {
      return {
        ...state,
        displayFooter: false
      };
    }

    default: {
      return state;
    }
  }
}

import React from "react";
import { ColumnView, SimpleAccordion } from "components/shared";
import { tran } from "utils/language";
import moment from "moment";
import { DialogPresets } from "components/shared/Dialog";
import { DateTime } from "components/shared/dateUtils";
import ReactMarkdownLoader from "components/shared/ReactMarkdownLoader";
import { useCurrentUserFolksRole } from "utils/hooks/redux";
import { isSysAdmin } from "utils/roles";

export function AppInfoDialog(props) {
  const { WS_VER: version, WS_BLD: release } = process.env;
  const when = moment(release, "YYYYMMDD.HHmm");
  const role = useCurrentUserFolksRole();
  const showChangelog = isSysAdmin(role);
  const [changelog, setChangelog] = React.useState("");
  
  React.useEffect(() => {
    if (!showChangelog)
      return;
    
    (async () => {
      const rs = await fetch("/CHANGELOG.md");
      if (rs.status === 200) {
        const text = await rs.text();
        setChangelog(text);
      }
    })();
  }, [showChangelog]);

  return (
    <DialogPresets.BrandedDialog {...props}>
      <h3 style={{ textAlign: "left" }}>{tran("footer.appInfo.about")}</h3>
      <br />
      <div style={{ textAlign: "left", width: "100%" }}>
        <ColumnView>
          <ColumnView.Item name={tran("footer.appInfo.version")} value={version} />
          <ColumnView.Item
            name={tran("footer.appInfo.releaseDate")}
            value={<DateTime at={when}/>}
          />
        </ColumnView>
      </div>
      {showChangelog && changelog && <style>{`
        .changelog-markdown {
          
        }
        .changelog-markdown h1 { display: none; }
        .changelog-markdown h2 { font-size: 1.3rem; margin: 1rem 0; }
        .changelog-markdown h3 { font-size: 1rem; margin: 1rem 0; }
        .changelog-markdown a { pointer-events: none; touch-action: none; cursor: default; }
      `}</style>}
      {showChangelog && changelog &&
        <><br/><SimpleAccordion header="Historia zmian" className="alignLeft" fluid>
          <ReactMarkdownLoader className="alignLeft changelog-markdown">
            {changelog}
          </ReactMarkdownLoader>
        </SimpleAccordion></>}
    </DialogPresets.BrandedDialog>
  );
}

import { Dictionary } from "./types";
import { emptyArray, emptyObject } from "./constants";

const fz = Object.freeze;
const bootstrap = (window as any).bootstrap; // nie importujemy bootstrap, bo chcemy wcześniej mieć to zaimportowane

export type Color = string;
export type Blend = ["blend", ColorSpec, ColorSpec, number];
export type ColorSpec = Color | Blend;

export interface Theme {
  readonly id: string,
  readonly name: string,
  readonly source: Dictionary<ColorSpec> // to co podane w makeTheme
  readonly colors: Dictionary<Color> // wartości końcowe po dziedziczeniu i podstawianiu
}

const SOWA = makeTheme("std" /* nazwa zmieniona by wymusić zmianę motywu na local */, "Sowa", null, {
  // GŁÓWNE KOLORY
  main: "#23507A", // Tło górnego panelu i headera modali
  primary: "#5484B2", // domyślne buttony, kolorowy tekst, linki
  confirm: "#248758", // potwierdzenia
  cancel: "#8E3636", // błędy
  warning: "#ffd666", // ostrzeżenia
  disabled: ["blend", "@text", "@bgMain", 10], // wyłączony (np. przycisk)
  
  // TŁA
  bgMain: "#FAFAFA", // tło aplikacji
  bgSub: "#FFFFFF", // tło segmentów, kart i innych elementów
  bgInv: "#313131", // odwrócony kolor głównego tła
  bgHighlighted: "#EDF4FA", // podświetlone tło (np. hover na elemencie listy)
  bgHeader: "#ECF2F9", // tło nagłówka strony
  bgBar: "#eff4de", // tło StickyBar
  // TEKST
  text: "#483b3b", // główny text, widoczny na tle bgMain, bgSub, bgHighlighted i bhHeader
  textLight: "#aca2a2", // jaśniejsza wersja zmiennej text
  textDisabled: ["blend", "@text", "@bgMain", 50], // kolor textu z tłem disabled
  
  mainInv: "#FCFCFC", // tekst na tle main
  primaryInv: "@mainInv", // tekst na tle primary
  confirmInv: "@primaryInv", // tekst na tle confirm
  cancelInv: "@primaryInv", // tekst na tle cancel
  warningInv: "#606060", // tekst na tle warning
  
  btnBgInv: "#ececec", // kolor tła odwróconego przycisku
  
  // KRAWĘDZIE
  border: "#D4D4D5", // główne krawędzie
  borderLight: "#E9E9EB", // mniej widoczne krawędzie
  shadow: "rgba(0,0,0,.2)", // kolor cienia (najczęściej do box-shadow)
  // shadow: "#D4D4D5" // kolor cienia (najczęściej do box-shadow)
  
  footer: "@bgSub",
  footerInv: "@borderLight",
  
  // FIXME: Yy, nie chcemy czegoś o szerszym zastosowaniu niż kolor tylko dla reguł udostępniania?
  //        Alternatywnie nie chcemy tego nazwać bardziej specyficznie?
  //        Może chcemy specyficzne kolory dla większej liczby miejsc w kodzie, jako aliasy ogólnych kolorów?
  highlight: "#ACD7FF", // Kolor tła elementu mark.
  
  primaryBg: ["blend", "@primary", "@bgMain", 33],
  warningBg: ["blend", "@warning", "@bgMain", 50],
  fatalBg:   ["blend", "@cancel", "@bgMain", 50],
  errorBg:   ["blend", "@fatalBg", "@warningBg", 50],
  
  eprWindow:   "#f0f0f0", // tło okna
  eprInput:    "#fff",    // tło inputów
  eprButton:   "#fdfdfd", // tło przycisku
  eprDisabled: "#e0e0e0", // tło wyłączonego komponentu
  eprBorder:   "#646464", // ramka inputa
  eprBorderL:  "#d0d0d0", // ramka jasna (przycisku)
  eprBorderA:  "#0078d4", // ramka aktywna
  eprError:    "#ff2424", // ramka komponentu z błędem oraz tekst błędu
  eprFrame:    "#dcdcdc", // ramka FRAME
  eprText:     "#000",    // kolor tekstu
  eprNegative:["blend", "@eprError", "@eprText", 66], // tekst inputa z ujemną liczbą
  lockerBg:   "#eff1f3", // kolor książkomatu
});

const CONTRAST = makeTheme("contrast", "Kontrast", SOWA, {
  main: "#000",
  primary: "#ff0",
  secondary: "#ff0",
  confirm: "#33FC3C",
  cancel: "#F91F26",
  warning: "#F7943A",
  
  bgSub: "#000",
  bgMain: "#000",
  bgHighlighted: "#7f8000",
  bgHeader: "#101000",
  bgBar: "#5a5e52",
  
  borderLight: "#ff0",
  border: "#ff0",
  shadow: "#ff0",
  
  text: "#fff",
  textLight: "#fafafa",
  // textDisabled: "#fff29",
  mainInv: "#fff",
  primaryInv: "#000000",
  warningInv: "#000000",
  confirmInv: "#000000",
  cancelInv: "#000000",
  btnBgInv: "#000000",
  footer: '#ff0',
  footerInv: '#000',
  
  highlight: "#ff0",
  lockerBg: "#000000",
});

// zauważyłem wiele wspólnego między sporą liczbą motywów, więc wyciągnąłem tutaj
const _cmn1 = makeTheme("", "", SOWA, {
  confirm: "#66BB6A",
  cancel: "#E53935",
  warning: "#F9A825",
  
  borderLight: "#F5F5F5",
  border: "#DDD",
  
  text: "#535353",
  
  mainInv: "#FAFAFA",
})

const TEAL = makeTheme("teal", "Morska zieleń", _cmn1, {
  main: "#009688",
  primary: "#009688",
  secondary: "#76b8b1",
  
  bgHighlighted: "#e4f1f0",
  bgHeader: "#f1fcfc",
  
  footerInv: "#DDD",
  
  highlight: "#acffe4"
});

const PURPLE = makeTheme("purple", "Fiołek", SOWA, {
  main: "linear-gradient(135deg, rgba(82,23,80,1) 0%, rgba(117,61,116,1) 52%, rgba(94,52,93,1) 100%)",
  primary: "#743771",
  secondary: "#a499a4",
  bgHighlighted: "#f0e5f7",
  bgHeader: "#f2ebf7",
  highlight: "#ffbafe"
});

const INDIGO = makeTheme("indigo", "Indygo", _cmn1, {
  main: "#3949AB",
  primary: "#3F51B5",
  secondary: "#C5CAE9",
  
  bgHighlighted: "#e2e5f9",
  bgHeader: "#E8EAF6",
  
  text: "#212121",
  textLight: "#7d8090",
  
  highlight: "#c0c9ff",
});

const FOREST_MOSS = makeTheme("forest_moss", "Leśny mech", _cmn1, {
  main: "#7E785D",
  primary: "#958F6F",
  secondary: "#DAD1A2",
  
  bgHighlighted: "#958f6f2e",
  bgHeader: "#958f6f0d",
  
  highlight: "#dbd4b4"
});

const DRY_WINE = makeTheme("dry_wine", "Wytrawne wino", _cmn1, {
  main: "#BA3044",
  primary: "#D96D68",
  secondary: "#ECB6BE",
  
  bgHighlighted: "#BA30440d",
  bgHeader: "#9BA30440d",
  
  highlight: "#ffb1bc"
});

const RED_WIDOW = makeTheme("red_widow", "Czerwona Wdowa", _cmn1, {
  main: "#41414D",
  primary: "#D94D41",
  secondary: "#D96D68",
  
  bgMain: "#f3f3f3",
  bgHighlighted: "#ffd6d3",
  bgHeader: "#41414D0d",
  
  highlight: "#d8d8d8"
});

const BLUE_WIDOW = makeTheme("blue_widow", "Niebieska Wdowa", _cmn1, {
  main: "#41414D",
  primary: "#258ecc",
  secondary: "#6aa3d8",
  
  bgMain: "#f3f3f3",
  shadow: "rgba(0,0,0,.12)",
  bgHighlighted: "#d3eeff",
  bgHeader: "#41414D0d",
  
  highlight: "#d8d8d8"
});

const GLOOMY_SHADOWS = makeTheme("gloomy_shadows", "Ponure cienie", _cmn1, {
  main: "#232639",
  primary: "#3C4154",
  secondary: "#A9A9A9",
  
  bgHighlighted: "#2326390d",
  bgHeader: "#2326390d",
  
  highlight: "#23263940"
});

const GROWN_BANANA = makeTheme("grown_banana", "Dojrzały banan", _cmn1, {
  main: "#f2a900",
  primary: "#f0c200",
  secondary: "#efdb00",
  
  bgHighlighted: "#f9fac0",
  bgHeader: "#fdfde5",
  
  highlight: "#ffc337"
});

const MATERIAL_BLUEGREY = makeTheme("material_bluegrey", "Jeans", SOWA, {
  main: "linear-gradient(135deg, rgba(55,71,79,1) 0%, rgba(80,107,120,1) 52%, rgba(65,93,107,1) 100%)",
  primary: "#547e93",
  bgHighlighted: "#dee9f0",
  bgHeader: "#ecf2f6",
  secondary: "#9fadb1",
  confirm: "#388E3C",
  cancel: "#c22724",
  warning: "#ddac33",
  
  highlight: "#adbec5"
});

const MATERIAL_BROWN = makeTheme("material_brown", "Brąz", SOWA, {
  main: "#5D4037",
  primary: "#8D6E63",
  secondary: "#a6a1a0",
  confirm: "#388E3C",
  cancel: "#E53935",
  warning: "#FBC02D",
  bgHighlighted: "#fceae3",
  bgHeader: "#f9f5f4",
  bgMain: "#f9f9f9",
  
  highlight: "#ded0cc"
});

const MATERIAL_PINK = makeTheme("flaming", "Flaming", SOWA, {
  main: "#b82f65",
  primary: "#cb4d7b",
  secondary: "#98697a",
  confirm: "#388E3C",
  cancel: "#811e1c",
  warning: "#e0a40f",
  bgHighlighted: "#f5e0e9",
  bgHeader: "#f7eaf0",
  
  highlight: "#ff99c1"
});

const DARK = makeTheme("dark", "Ciemny", SOWA, {
  main: "#2c3442",
  mainInv: "#F5F5F5",
  primary: "#4f9e9c",
  secondary: "#87adac",
  confirm: "#5eb161",
  cancel: "#f2263b",
  warning: "#c4b745",
  disabled: "#ffffff08",
  
  bgSub: "#3b4352",
  bgMain: "#2c3442",
  bgInv: "#fff",
  bgHighlighted: "#4f7978",
  bgHeader: "#3d4b57",
  bgBar: "#5a5e52",
  
  btnBgInv: "#373d48",
  
  border: "#565962",
  borderLight: "#424853",
  shadow: "#122c44",
  
  text: "#F5F5F5",
  textLight: "#b6b6b6",
  textDisabled: "#ffffff29",
  
  highlight: "#77c7c6",
  lockerBg: "#313a49",
});

const DARK_YELLOW = makeTheme("dark_yellow", "Ciemna żółć", SOWA, {
  main: "#3c3c3c",
  primary: "#f8b908",
  secondary: "#00000063",
  confirm: "#66BB6A",
  cancel: "#E53935",
  warning: "#F9A825",
  disabled: "#ffffff08",
  
  bgSub: "#565656",
  bgMain: "#656565",
  bgInv: "#fff",
  bgHighlighted: "#484848",
  bgHeader: "#4e4e4e",
  bgBar: "#5a5e52",
  
  btnBgInv: "#484848",
  
  borderLight: "#585757",
  border: "#424242",
  
  text: "#F5F5F5",
  textLight: "#b6b6b6",
  textDisabled: "#ffffff29",
  mainInv: "#F5F5F5",
  primaryInv: "@main",
  
  highlight: "#f8b908",
  lockerBg: "#616161",
});

const TRUE_DARK = makeTheme("true_dark", "Prawdziwa czerń", SOWA, {
  // GŁÓWNE KOLORY
  main: "#121212", // Tło górnego panelu i headera modali
  primary: "#005db7", // domyślne buttony, kolorowy tekst, linki
  confirm: "#22a767", // potwierdzenia
  cancel: "#B00020", // błędy
  warning: "#daa000", // ostrzeżenia
  
  disabled: "#ffffff08",
  textDisabled: "#ffffff29",
  
  // TŁA
  bgMain: "#121212", // tło aplikacji
  bgSub: "#212121", // tło segmentów, kart i innych elementów
  bgInv: "#FFF", // odwrócony kolor głównego tła
  bgHighlighted: "#212121", // podświetlone tło (np. hover na elemencie listy)
  bgHeader: "#ECF2F9", // tło nagłówka strony
  bgBar: "#5a5e52",
  
  // TEKST
  text: "#fff", // główny text, widoczny na tle bgMain, bgSub, bgHighlighted i bhHeader
  textLight: "#bdbdbd", // jaśniejsza wersja zmiennej text
  
  mainInv: "#FFF", // tekst na tle main
  
  btnBgInv: "#121212", // kolor tła odwróconego przycisku
  
  // KRAWĘDZIE
  border: "#2f2f2f", // główne krawędzie
  borderLight: "#1f1f1f", // mniej widoczne krawędzie
  shadow: "rgba(0,0,0,.2)", // kolor cienia (najczęściej do box-shadow)
  // shadow: "#D4D4D5" // kolor cienia (najczęściej do box-shadow)
  
  highlight: "#0082ff",
  lockerBg: "#0a0a0a",
});

const _themes = fz([
  SOWA,          TEAL,              PURPLE,
  INDIGO,        FOREST_MOSS,       DRY_WINE,
  RED_WIDOW,     BLUE_WIDOW,        GLOOMY_SHADOWS,
  GROWN_BANANA,  MATERIAL_BLUEGREY, MATERIAL_BROWN,
  MATERIAL_PINK, DARK,              DARK_YELLOW,
  TRUE_DARK
]);

const custom = bootstrap.customTheme as Dictionary<string>;
const customBase = custom && custom.parent && _themes.find(theme => theme.id === custom.parent) || SOWA;
const DEFAULT = custom && Object.keys(custom).length > 0
  ? makeTheme("local", "Domyślny", customBase, custom)
  : SOWA;

const customCSS = bootstrap.customCSS || emptyObject as {
  titleSize: string,
};

export const THEME_LIST = fz([
  ...(DEFAULT !== SOWA ? [DEFAULT] : emptyArray), // motyw lokalny tylko jeśli inny niż Sowa
  ..._themes
]);

export const THEME_MAP = fz(new Map<string | undefined, Readonly<Theme>>(
  // @ts-ignore // gubi się
  THEME_LIST
    .map(theme => [theme.id, theme])
    .concat([["contrast", CONTRAST]])
));

function makeTheme(id: string, name: string, parent: Theme | null, source: Dictionary<ColorSpec>): Readonly<Theme> {
  let cache: null | Dictionary<Color> = null;
  return fz({
    id, name, source,
    get colors(): Dictionary<Color> {
      if (!cache) {
        // budujemy motywy leniwie, bo szkoda baterii na nieużywane motywy
        const merged: Dictionary<ColorSpec> = { ...(parent && parent.colors || emptyObject), ...(parent && parent.source || emptyObject), ...source };
        const resolved: Dictionary<Color> = {};
        let keys = Object.keys(merged);
        let lastCount = 0;
  
        const rxRGBA = /^#([0-9a-f][0-9a-f])([0-9a-f][0-9a-f])([0-9a-f][0-9a-f])([0-9a-f][0-9a-f])?$/i;
        const rxShort = /^#([0-9a-f])([0-9a-f])([0-9a-f])$/i;
        const round = Math.round, min = Math.min;
        const defer = {};
        const error = {};
        
        function parseColor(color: Color) {
          const m = rxRGBA.exec(color)!;
          if (!m) throw error;
          const r = parseInt(m[1], 16);
          const g = parseInt(m[2], 16);
          const b = parseInt(m[3], 16);
          const a = m[4] ? parseInt(m[4], 16) : 255;
          return [r, g, b, a];
        }
        
        function blend(top: number, bottom: number, alpha: number) {
          return min(255, round((top * alpha + bottom * (255 - alpha)) / 255));
        }
        
        function blendColor(top: Color, bottom: Color, alpha: number) {
          const [R, G, B, A] = parseColor(top);
          const [r, g, b, a] = parseColor(bottom);
          const a2 = min(255, alpha * A * 0.01); //alpha * 2.55 * A / 255
          const r2 = blend(R, r, a2);
          const g2 = blend(G, g, a2);
          const b2 = blend(B, b, a2);
          return `#${r2.toString(16)}${g2.toString(16)}${b2.toString(16)}${a.toString(16)}`;
        }
  
        function resolve(spec: ColorSpec): Color {
          if (typeof spec === "string") {
            if (spec.startsWith("@")) {
              const color = resolved[spec.substring(1).trim()];
              if (color)
                return color;
              else
                throw defer;
            }
            else if (rxShort.test(spec))
              return `#${spec[1]}${spec[1]}${spec[2]}${spec[2]}${spec[3]}${spec[3]}`;
            else
              return spec;
          }
          else if (spec[0] === "blend") {
            const top = resolve(spec[1]);
            const bottom = resolve(spec[2]);
            return blendColor(top, bottom, min(spec[3], 100));
          }
          throw error;
        }
        
        let left;
        while (keys.length > 0 && keys.length !== lastCount) {
          left = [] as string[];
          for (let key of keys) {
            const val = merged[key];
            try {
              resolved[key] = resolve(val);
            }
            catch (e) {
              if (e === error) {
                console.error(`Cannot parse color ${key} in theme ${name}`, val);
                resolved[key] = "#00000000";
              }
              else if (e === defer)
                left.push(key);
              else
                throw e;
            }
          }
          
          lastCount = keys.length;
          keys = left;
        }
        
        if (left && left.length > 0) {
          console.error(`Failed to resolve colors in theme ${name}`, left);
          for (const key of left)
            resolved[key] = "#00000000";
        }
        
        cache = fz(resolved);
      }
      return cache;
    }
  })
}

export const FONT_LIST = fz([
  fz({ id: "Arial",       mono: "Andale Mono", name: "Arial", fallback: ", Helvetica" }),
  fz({ id: "Roboto",      mono: "Roboto Mono", name: "Roboto" }),
  fz({ id: "Roboto Slab", mono: "Roboto Mono", name: "Roboto Slab" }),
  fz({ id: "sans-serif",  mono: "monospace",   name: "<sans-serif>" }),
  fz({ id: "serif",       mono: "monospace",   name: "<serif>" }),
]);

export const FONT_MAP = fz(new Map(FONT_LIST.map(font => [font.id, font])));

const defaultFont = bootstrap.defaultFont || "Arial";
export const DEFAULT_FONT = FONT_MAP.get(FONT_LIST.filter(font => font.id === defaultFont).concat(FONT_LIST[0])[0].id)!;

export function getThemeByName(id?: string): Readonly<Theme> { // nazwa się nie zgadza przez refaktoring
  return THEME_MAP.get(id) || THEME_LIST[0];
}

export function isDarkTheme(id?: string) {
  const theme = THEME_MAP.get(id) || THEME_LIST[0];
  return isDarkColor(theme.colors.bgMain);
}

export function getFont(id: string) {
  return FONT_MAP.get(id as any) || DEFAULT_FONT;
}

function setMeta(name: string, value: string) {
  // @ts-ignore
  for (let el of document.getElementsByName(name).values()) {
    if (el.tagName === "META") {
      (el as HTMLMetaElement).content = value;
      return;
    }
  }
  const el = document.createElement("meta");
  el.name = name;
  el.content = value;
  document.head.appendChild(el);
}

/**
 * Ustawienie wybranego motywu
 * @param {String} id Klucz motywu
 */
export function setTheme(id: string) {
  const theme = THEME_MAP.get(id) || THEME_LIST[0];
  const colors = theme.colors;
  for (let key in colors) {
    document.body.style.setProperty("--" + key, colors[key]);
  }
  for (let key in customCSS) {
    document.body.style.setProperty("--" + key, customCSS[key]);
  }
  setMeta("theme-color", colors.main);
  if (id === "contrast")
    document.documentElement.classList.add("high-contrast");
  else
    document.documentElement.classList.remove("high-contrast");
}

export function setFont(id: string) {
  const font = FONT_MAP.get(id as any) || DEFAULT_FONT;
  document.body.style.setProperty("--font", font.id + ((font as any).fallback || "") + ", sans-serif");
  document.body.style.setProperty("--monoFont", font.mono + ", monospace");
}

/**
 * Zwraca czy podany kolor jest ciemny zgodnie z rekomendacjami ITU.
 * @param {string} hex 
 */
function isDarkColor(hex: string): boolean {
  if (!hex.startsWith("#"))
    return false;
  
  var c = hex.substring(1);    // strip #
  var rgb = parseInt(c, 16);   // convert rrggbb to decimal
  var r = (rgb >> 16) & 0xff;  // extract red
  var g = (rgb >>  8) & 0xff;  // extract green
  var b = (rgb >>  0) & 0xff;  // extract blue
  
  var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

  return luma < 128;
}

export function localThemeSave(themeId: string, font: string) {
  const theme = THEME_MAP.get(themeId) || THEME_LIST[0];
  localStorage.setItem("theme", `${theme.id}:${font}`);
}

export function localThemeGet() {
  let themeId = "", fontId = "";
  try {
    const x = (localStorage.getItem("theme") || "").split(":", 2)
    themeId = x[0];
    fontId = x[1] || fontId;
  }
  catch (e) {}

  return { theme: themeId, font: fontId }
}

export function localThemeReset() {
  localStorage.removeItem("theme");
  setTheme("");
  setFont(FONT_LIST[0].id);
}


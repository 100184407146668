import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { Overlay } from "../layout/overlays";

class Popup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      position: props.position,
      open: false,
      top: 10,
      left: 0,
      bottom: "auto",
      right: "auto",
      transform: ""
    };
  }

  /** === LIFECYCLE === */

  componentDidUpdate(prevProps, prevState) {
    if (this.state.open !== prevState.open && this.state.open)
      this.verifyPosition();

    if (prevProps.position !== this.props.position) {
      this.setState({ position: this.props.position });
    }
  }

  showPopup = () => {
    const { onOpen } = this.props;

    this.setPosition(this.getPosition());

    if (onOpen) onOpen();
    this.togglePopup(true);
  };

  hidePopup = () => {
    const { onClose } = this.props;

    if (onClose) onClose();
    this.togglePopup(false);
  };

  /** === UTILS === */

  /**
   * @description Toggles the popup.
   * @prop {bool} toggle
   */
  togglePopup = toggle => {
    const { closeOnScroll } = this.props;

    this.setState({ open: toggle });
    if (closeOnScroll) this.toggleScrollEventListener(toggle);
    let blur = this.props.closeOnBlur ?? this.props.on === "click";
    if (toggle) {
      document.body.addEventListener("mousedown", this.handleOutsideClick);
      blur && document.body.addEventListener("focusin", this.handleOutsideClick);
    }
    else {
      document.body.removeEventListener("mousedown", this.handleOutsideClick);
      blur && document.body.removeEventListener("focusin", this.handleOutsideClick);
    }
  };

  /**
   * @description Toggles the scroll event listener.
   * @prop {bool} listening
   */
  toggleScrollEventListener = listening => {
    if (!listening)
      document
        .querySelector("body")
        .removeEventListener("scroll", this.hidePopup, { capture: true });
    else
      document.querySelector("body").addEventListener("scroll", this.hidePopup, { capture: true });
  };

  /**
   * @description Sets the position of the Popup.
   */
  setPosition = params => {
    this.setState({ ...params });
  };

  /**
   * @description Translates text to styles.
   * @prop {string} position;
   * @returns {object}
   */
  getPosition = (position = this.state.position) => {
    const [posA, posB] = position.split(" ");
    let top, left, bottom, right, transform;

    const triggerRect = this.triggerWrapperRef.getBoundingClientRect();

    const triggerScrollY = triggerRect.top + window.scrollY;
    const triggerScrollX = triggerRect.left + window.scrollX;

    if (posB === "center") {
      switch (posA) {
        case "left":
          left = "auto";
          right = window.innerWidth - triggerScrollX + 10;
          top = triggerScrollY + triggerRect.height / 2;
          transform = "translateY(-50%)";
          break;
        case "right":
          left = triggerScrollX + triggerRect.width + 10;
          top = triggerScrollY + triggerRect.height / 2;
          transform = "translateY(-50%)";
          break;
        case "bottom":
          top = triggerScrollY + triggerRect.height + 10;
          left = triggerScrollX + triggerRect.width / 2;
          transform = "translateX(-50%)";
          break;
        default:
        case "top":
          bottom = window.innerHeight - triggerScrollY + 10;
          top = "auto";
          left = triggerScrollX + triggerRect.width / 2;
          transform = "translateX(-50%)";
          break;
      }
    } else {
      switch (posA) {
        case "bottom":
          top = triggerScrollY + triggerRect.height + 10;
          bottom = "auto";
          break;
        case "right":
          left = triggerScrollX + triggerRect.width + 10;
          right = "auto";
          break;
        case "left":
          left = "auto";
          right = window.innerWidth - triggerScrollX + 10;
          break;
        default:
        case "top":
          top = "auto";
          bottom = window.innerHeight - triggerScrollY + 10;
          break;
      }

      switch (posB) {
        case "right":
          left = "auto";
          right = window.innerWidth - triggerScrollX - triggerRect.width;
          break;

        case "top":
          top = triggerScrollY;
          bottom = "auto";
          break;

        case "bottom":
          top = "auto";
          bottom = window.innerHeight - triggerScrollY - triggerRect.height;
          break;

        default:
        case "left":
          left = triggerScrollX;
          right = "auto";
          break;
      }
    }

    return { top, left, right, bottom, transform };
  };

  /**
   * @description Checks if popup is not offscreen.
   */
  verifyPosition = () => {
    if (!this.contentRef) return;
    const rect = this.contentRef.getBoundingClientRect();
    const {
      x: popup_x,
      y: popup_y,
      width: popup_width,
      height: popup_height
    } = rect;

    const isOffscreenX =
      popup_x + popup_width > window.innerWidth || popup_x < 0;
    const isOffscreenY =
      popup_y + popup_height > window.innerHeight || popup_y < 0;

    if (!isOffscreenX && !isOffscreenY) return;

    const { position } = this.state;
    const [posA, posB] = position.split(" ");
    let _posA, _posB;

    if (isOffscreenX) {
      if (posA === "left") _posA = "right";
      if (posA === "right") _posA = "left";
      if (posB === "left") _posB = "right";
      if (posB === "right") _posB = "left";
    }

    if (isOffscreenY) {
      if (posA === "top") _posA = "bottom";
      if (posA === "bottom") _posA = "top";
      if (posB === "top") _posB = "bottom";
      if (posB === "bottom") _posB = "top";
    }

    if (!_posA) _posA = posA;
    if (!_posB) _posB = posB;

    this.setState({ position: `${_posA} ${_posB}` });
  };

  /**
   * @description Exclude props which have been already used.
   * @returns {object}
   */
  sortProps() {
    const propTypes = Object.keys(Popup.propTypes),
      props = { ...this.props };

    // Delete all props applied in classes etc..
    for (var i = 0; i < propTypes.length; i++) delete props[propTypes[i]];

    return props;
  }

  /** === HANDLERS === */

  handleOutsideClick = e => {
    if (!this.contentRef) return;
    if (
      (this.contentRef && !this.contentRef.contains(e.target)) &&
      !this.triggerWrapperRef?.contains(e.target)
    ) {
      this.hidePopup();
    }
  };

  handleTriggerClick = () => {
    const { on } = this.props;
    const { open } = this.state;

    if (on === "click") {
      if (open) this.hidePopup();
      else this.showPopup();
    }
  };

  handleContentRef = e => {
    this.contentRef = e;
    this.verifyPosition();
    if (this.props.ref) this.props.ref(e);
  };

  handleMouseEnter = () => {
    const { on } = this.props;
    const { open } = this.state;
    if (on === "hover" && !open) this.showPopup();
  };

  handleMouseLeave = () => {
    const { on } = this.props;
    const { open } = this.state;
    if (on === "hover" && open) this.hidePopup();
  };

  /** === RENDERS === */

  /**
   * @description Renders the trigger.
   * @returns {element}
   */
  renderTrigger = () => {
    const { trigger } = this.props;

    return (
      <div
        className="popup-trigger"
        onClick={this.handleTriggerClick}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        ref={e => (this.triggerWrapperRef = e)}
      >
        {typeof trigger === "function" ? trigger(this.state.open, this.handleTriggerClick) : trigger}
      </div>
    );
  };

  render() {
    const { pointing, content, style, className } = this.props;
    const { open, top, left, bottom, right, transform, position } = this.state;

    const styles = { top, left, bottom, right, transform, ...style };

    const classes = cx(
      "sowa",
      "popup3",
      replaceWhitespace(position),
      pointing && "pointing",
      className
    );

    const props = {
      style: styles,
      className: classes
    }

    return (
      <React.Fragment>
        {this.renderTrigger()}
        {open && (
          <Overlay type="transparent">
            <div
              {...this.sortProps()}
              onMouseEnter={this.handleMouseLeave}
              {...props}
              ref={this.handleContentRef}>
              {typeof content === "function" ? content(this.hidePopup) : content}
            </div>
          </Overlay>
        )}
      </React.Fragment>
    );
  }
}

/**
 * @description Replace whitespace with a string.
 * @param {string} val
 * @param {string} symbol
 */
const replaceWhitespace = (val, symbol = "-") => {
  return val.replace(" ", symbol);
};

Popup.defaultProps = {
  on: "click",
  position: "top left",
  pointing: false,
  closeOnScroll: true,
  closeOnBlur: null,
  className: "",
  style: {}
};

Popup.propTypes = {
  trigger: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func // wywołuje funkcje i przekazuje this.state.open jako parametr
  ]).isRequired,
  content: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func
  ]).isRequired,
  on: PropTypes.oneOf(["hover", "click"]),
  pointing: PropTypes.bool,
  closeOnScroll: PropTypes.bool,
  closeOnBlur: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  position: PropTypes.oneOf([
    "top left",
    "top right",
    "bottom left",
    "bottom right",
    "right top",
    "right bottom",
    "left top",
    "left bottom",
    "left center",
    "top center",
    "right center",
    "bottom center"
  ]),
  onOpen: PropTypes.func,
  onClose: PropTypes.func
};

export default Popup;

import { sessionClientByPath } from "capi/appclient";
import * as Folks from "capi/folks";

import store from "store";
import bootstrap from "bootstrap";

// --------------------   UŻYTKOWNIK   ----------------------
// ----------------------------------------------------------

/**
 * Pobranie danych Folks użytkownika
 * @param {String} user_id Identyfikator użytkownika (unefined dla zalogowanego)
 * @param {Object} params Dodatkowe ustawienia
 * @param {Boolean} params.props Czy zwracać dane osobowe
 * @param {Boolean} params.flags Czy zwracać listę flag
 * @param {Boolean} params.addresses Czy zwracać listę adresów użytkownika
 * @param {Boolean} params.relatives Czy zwracać listę krewnych
 * @param {Boolean} params.dereference Czy w przypadku, gdy użytkownik jest aliasem, zwracać informację o właściwym użytkowniku
 * @param {Boolean} params.bonds Czy zwracać listę powiązań użytkownika
 */
export function fetchUserFolks(user_id, params) {
  const Client = sessionClientByPath(store, "folks");
  return Client.executeSingle(new Folks.UserInfo({ user_id, ...params }));
}

/**
 * Wczytanie wartości klucza ze storage
 * @param {String} key Klucz w storage
 */
export function getStorageKey(key) {
  const Client = sessionClientByPath(store, "folks");
  return Client.executeSingle(new Folks.StorageGet(key));
}

/**
 * Zapisanie nowej wartości klucza w storage
 * @param {String} key Nazwa klucza
 * @param {String} value Wartość
 */
export function saveStorageKey(key, value) {
  const Client = sessionClientByPath(store, "folks");
  return Client.executeSingle(new Folks.StoragePut(key, value));
}


// ----------------   LISTA UŻYTKOWNIKÓW   ------------------
// ----------------------------------------------------------

/**
 * Pobranie danych Folks wybranych użytkowników (zwraca tablicę z results)
 * @param {Set} users_ids identyfikatory użytkowników do pobrania
 */
export function fetchUsers(users_ids) {
  const Client = sessionClientByPath(store, "folks");
  const exec = [];
  users_ids.forEach(item => {
    exec.push(new Folks.UserInfo({ user_id: item }));
  });
  return Client.execute(...exec);
}

/**
 * Usunięcie niepotwierdzonego adresu po tokenie
 * @param {String} token
 */
export function addressCancel(token) {
  return sessionClientByPath(store, "folks").executeSingle(
    new Folks.AddressCancel(token)
  );
}

// -----------------------   SESJE   ------------------------
// ----------------------------------------------------------

/**
 * Utworzenie sesji na podstawie paszportu
 * @param {String} passport_id
 * @param {String} passport_key
 */
export function createSessionWithPassport(passport_id, passport_key) {
  const domain = bootstrap.domain;
  const Client = bootstrap.folks.client.withAuth(
    101,
    passport_id,
    passport_key,
    domain
  );
  return Client.executeSingle(new Folks.SessionCreate(domain));
}

// ----------------------   RAPORTY   -----------------------
// ----------------------------------------------------------

export function reportCount(report_name, date_from, date_to) {
  const Client = sessionClientByPath(store, "folks");
  return Client.executeSingle(new Folks.ReportCount(report_name, date_from, date_to));
}
import bootstrap from "bootstrap";
import { CapiClient, CapiCommand, sessionAuth } from "./client";

import { LOGOUT_SUCCESS, RESET_SESSION_CLOCK } from "sagas/types";
import { call, select } from "redux-saga/effects";
import { emptyArray, emptyObject } from "../utils/constants";
import {getLanguage} from "../utils/language";
import store from "store";

const languageMap = { en_US: "en_GB" }

let cachedClients = null;
let cachedSession = null;
let cachedSessionKey = null;

export class ScatterGatherClient extends CapiClient {
  constructor(parentClient, auth) {
    super(parentClient.url)
    this.parentClient = parentClient;
    this.auth = auth;
    this.mode = 0;
    this.lang = parentClient.lang;
    this.log = parentClient.log;
    this.wait = parentClient.wait;
    this._onExecuted = [...parentClient._onExecuted];
    this._queue = [];
    this._promise = null;
    this._recordsByConfig = new Map();
  }
  
  async execute(...commands) {
    const L = commands.length;
    if (L > 1 || this.wait) {
      // wielu komend nie agregujemy, bo `mode` może mieć znaczenie
      // komend oczekujących też nie (choć jest to wspierane z tego co pamiętam)
      this.mode = this.parentClient.mode;
      this._doRetry = this.parentClient._doRetry;
      return super.execute(...commands);
    }
    else if (L < 1)
      return emptyArray;
    
    const command = commands[0];
    if (command.exec[0] === "sowaFormatRecord" && !(command instanceof SowaFormatRecordAgg)) {
      return await this.aggregateFormatRecord(command);
    }
    else if ((command.exec[0] === "sowaCollectionCheckItems" || command.exec[0] === "sowaThematicCheckItems") && !(command instanceof SowaCheckItemsAgg)) {
      return await this.aggregateCheckItems(command);
    }
    
    const i = this._queue.length;
    //if (i > 0)
    //  console.log(`Optimized CAPI request: ${this._queue.map(command => command.exec[0]).join(",")} + ${commands.map(command => command.exec[0]).join(",")}`);
    
    this._queue.push(...commands);
    
    if (this._promise === null) {
      this._promise = new Promise(this.promisedExecution)
    }
    
    // promise jest dla agregacji wszystkich komend, więc musimy wyciąć wyniki tych z aktualnego wywołania
    const all = await this._promise;
    return all.slice(i, i + L);
  }
  
  promisedExecution = (resolve, reject) => {
    setTimeout(this.executeLater, 0, resolve, reject)
  }
  
  executeLater = (resolve, reject) => {
    const queue = this._queue;
    this._queue = [];
    this._promise = null;
    
    this.mode = 0;
    this._doRetry = true;
    
    super
      .execute(...queue)
      .catch(err => reject(err))
      .then(results => resolve(results))
  }
  
  async aggregateFormatRecord(command) {
    // agregacja wywołań do sowaFormatRecord w jedną komendę
    const [, [kind, records], kwargs] = command.exec;
    const { remarks, no_linkage, no_references, no_media } = kwargs || emptyObject;
    // musimy prowadzić osobne kolejki dla różnych zestawów argumentów
    const configKey = `${kind}/${(remarks || emptyArray).join(";")}/${+!!(no_linkage || false)}${+!!(no_references || false)}${+!!(no_media || false)}`;

    const self = this;
    const { _recordsByConfig } = this;
    let Q = _recordsByConfig.get(configKey);
    if (!Q) {
      const thisQ = Q = {
        records: [],
        kind,
        kwargs: { remarks, no_linkage, no_references, no_media },
        promise: new Promise((resolve, reject) => {
          setTimeout(() => {
            const Q = thisQ;
            _recordsByConfig.delete(configKey);
            self.mode = 0;
            self._doRetry = true;
            self
              .execute(new SowaFormatRecordAgg(Q.kind, Q.records, Q.kwargs))
              .catch(err => reject(err))
              .then(results => resolve(results))
          }, 0);
        }),
      }
      _recordsByConfig.set(configKey, Q);
    }

    let i = Q.records.length;
    Q.records.push(...records);
    
    // console.log("FRagg", configKey, i, ...records);
    
    const [executed] = await Q.promise;
    if (executed.status === 200) {
      const results = executed.result.data.items;
      // console.log("FRagg.result", records[0], results[i]);
      
      for (let r = 0; r < records.length; r++) {
        const input = records[r];
        const output = results[i + r];
        if (input.rec_no !== output.rec_no)
          console.error("FormatRecord aggregation mismatch", i, records.length, results.length, input, output);
      }
      
      command.setResult({
        ...executed.result,
        data: { items: results.slice(i, i + records.length) },
      })
    }
    else {
      command.setResult({ ...executed.result });
    }

    return [command];
  }  
  async aggregateCheckItems(command) {
    const [commandName, [collId, records]] = command.exec;
    const configKey = commandName;
    
    const self = this;
    const { _recordsByConfig } = this;
    let Q = _recordsByConfig.get(configKey);
    if (!Q) {
      const thisQ = Q = {
        records: [],
        commandName,
        collId,
        promise: new Promise((resolve, reject) => {
          setTimeout(() => {
            const Q = thisQ;
            _recordsByConfig.delete(configKey);
            self.mode = 0;
            self._doRetry = true;
            self
              .execute(new SowaCheckItemsAgg(Q.commandName, Q.collId, Q.records))
              .catch(err => reject(err))
              .then(results => resolve(results))
          }, 0);
        }),
      }
      _recordsByConfig.set(configKey, Q);
    }

    let i = Q.records.length;
    Q.records.push(...records);
    
    const [executed] = await Q.promise;
    if (executed.status === 200) {
      let { present: allPresent, absent: allAbsent } = executed.result.data;
      allPresent = new Set(allPresent);
      allAbsent = new Set(allAbsent);
      const present = [], absent = [];
      
      for (let r = 0; r < records.length; r++) {
        const input = records[r];
        if (allPresent.has(input))
          present.push(input);
        if (allAbsent.has(input))
          absent.push(input);
      }
      
      command.setResult({
        ...executed.result,
        data: { present, absent },
      })
    }
    else {
      command.setResult({ ...executed.result });
    }

    return [command];
  }
  
  copyWith (changes) {
    const copy = super.copyWith(changes);
    copy._onExecuted = [...copy._onExecuted];
    copy._queue = [];
    copy._promise = null;
    copy._recordsByConfig = new Map();
    return copy;
  }
}

function getCachedSessionClient(parentClient, ssid, sskey) {
  if (ssid !== cachedSession || sskey !== cachedSessionKey) {
    cachedClients = new Map();
    cachedSession = ssid;
    cachedSessionKey = sskey;
  }
  
  let cachedClient = cachedClients.get(parentClient)
  if (typeof cachedClient === "undefined") {
    cachedClient = new ScatterGatherClient(parentClient, sessionAuth(ssid, sskey));
    cachedClients.set(parentClient, cachedClient)
  }
  
  return cachedClient;
}

// utworzenie klienta logującego się sesją mając podanego klienta już skonfigurowanego pod jakiś URL
export function sessionClient(store_, parentClient, throwOnFailure = true) {
  if (store_ instanceof CapiClient) {
    throwOnFailure = parentClient ?? true;
    parentClient = store_;
    store_ = store;
  }
  
  if (parentClient instanceof ScatterGatherClient || parentClient.dontLogin)
    return parentClient;
  
  if (!parentClient) throw new Error("No parent client given.");

  const state = typeof store_.getState === "function" ? store_.getState() : store_;
  const session = state.session;
  if (!session || !session.session_id) {
    if (throwOnFailure) throw new Error("No session to create client");
    else return null;
  }
  
  const client = getCachedSessionClient(parentClient, session.session_id, session.session_key);
  
  // FIXME: na razie przeładowujemy stronę przy zmianie języka, więc nie monitorujemy zmian tego
  const language = getLanguage();
  if (language) client.lang = languageMap[language] || language;

  client.onExecuted(onExecuted);

  return client;
}

let resetTimer = null;
function resetClock() {
  resetTimer = null;
  if (document.visibilityState === "visible")
    store.dispatch({ type: RESET_SESSION_CLOCK });
}

function onExecuted(commands) {
  if (!commands.length) return;
  
  if (commands[0].status !== 401) {
    if (
      commands[0].exec[0] !== "folksSessionInfo" &&
      commands[0].exec[0] !== "folksSessionDelete"
    ) {
      // FIXME: to jest bez sensu, trzeba przepisać
      
      if (resetTimer === null)
        resetTimer = setTimeout(resetClock, 5000);
    }
  } else {
    const auth = this.auth;
    if (commands[0].exec[0] !== "folksSessionInfo" && (auth[0] === 102 || auth[0] === 112)) {
      // na starcie aplikacji wczytujemy session_id z localStorage i wysyłamy zapytanie folksSessionInfo
      // może się okazać, że session_id jest już nieaktualne - wtedy nie dojdzie do zalogowania, a więc nie wysyłamy
      // także akji do wylogowania
      store.dispatch({ type: LOGOUT_SUCCESS, payload: { sessionExpired: true, sessionId: auth[1], sessionKey: auth[0] === 102 ? auth[2] : "" } });
    }
  }
}

// utworzenie klienta logującego się sesją na postawie ścieżki obiektowej w `bootstrap`,
// np. sessionClientByPath(store, "folks") === sessionClient(store, bootstrap.folks.client)
export function sessionClientByPath(store_, ...path) {
  if (typeof store_ !== "object") {
    path.unshift(store_);
    store_ = store;
  }
  
  let where = bootstrap;
  for (let i = 0; i < path.length; i++) {
    where = where[path[i]];
    if (!where) throw new Error(`No such app: ${path.join(".")}`);
  }

  const client = where.client;
  if (!client) throw new Error(`No such client: ${path.join(".")}`);

  return sessionClient(store_, client);
}

// efekt dla redux-saga
// TODO: by mieć testowalną wersję tego trzeba być użyć getContext
export function* sagaCapi(...args) {
  function apiCall(state, args) {
    let i = 0;
    while (typeof args[i] !== "object") i++;
    return sessionClientByPath(state, ...args.slice(0, i)).execute(
      ...args.slice(i)
    );
  }

  const state = yield select();
  const result = yield call(apiCall, state, args);
  return result;
}

// absolutnie nie wolno tego eksportować
class SowaFormatRecordAgg extends CapiCommand
{
  constructor (kind, records, kwargs) {
    super(["sowaFormatRecord", [kind, records], kwargs]);
  }
}

class SowaCheckItemsAgg extends CapiCommand{
  constructor (command, collId, recNos) {
    super([command, [collId, recNos]]);
  }
}

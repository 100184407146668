import React, { Component } from "react";
import PropTypes from "prop-types";

import { Modal, Input, Segment, Message, Tag, Icon, Button } from "components/shared";

import { tran, translate } from "utils/language";

class ModalList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionsToShow: [...props.options],
      value: props.value || [],
      open: false
    };

    this.triggerRef = null;
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({ value: this.props.value || [] });
    }

    if (this.props.options !== prevProps.options) {
      this.setState({ optionsToShow: this.props.options });
    }
  }

  toggleOpen = (open = !this.state.open) => {
    this.setState({ open });
    if (this.props.onOpen) this.props.onOpen();
    if(!open) 
      this.setState({ value: this.props.value, optionsToShow: this.props.options });
  };

  handleClose = () => {
    this.toggleOpen(false);
    this.props.onClose(this.state.value);
  };

  isOptionChecked(value) {
    if (this.props.multiple) {
      for (let i = 0; i < this.state.value.length; i++) {
        if (this.state.value[i] === value) {
          return true;
        }
      }
      return false;
    } else {
      if (this.state.value === value) {
        return true;
      }
      return false;
    }
  }

  handleSearchInput(value) {
    if (value.length === 0) {
      this.setState({
        optionsToShow: [...this.props.options]
      });
    } else {
      let newOptions = [];
      this.state.optionsToShow.forEach(option => {
        if (
          option.text
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        ) {
          newOptions.push(option);
        }
      });
      this.setState({
        optionsToShow: newOptions
      });
    }
  }

  toggleValue(val) {
    let values = [...this.state.value || []];
    let index = values.indexOf(val);
    if (index > -1) {
      values.splice(index, 1);
    } else {
      if (this.props.multiple) {
        values.push(val);
      } else {
        values = val;
      }
    }

    this.setState({
      value: values
    });
  }

  clearValues() {
    let value = this.props.multiple ? [] : "";
    this.setState({
      value
    });
  }

  addAll() {
    let value = this.state.optionsToShow.map(option => {
      return option.value;
    });

    this.setState({
      value
    });
  }

  isValueEmpty() {
    if (!this.state.value || this.state.value.length === 0) {
      return true;
    }
    return false;
  }

  onOptionDoubleClick = () => {
    if (this.props.multiple) return;

    this.handleClose();
  }

  render() {
    const renderContent = () => {
      const fluidClass = this.props.fluidOptions ? "fluid" : "";
      
      const options =
        this.state.optionsToShow.length === 0 && !this.props.emptyOption ? (
          this.props.options.length > 0 ? (
            <Message type="error" content={tran("unique.noSearchResults")} />
          ) : (
            <Message type="error" content={tran("unique.noOptionsToShow")} />
          )
        ) : (
          <Segment className={`modal-list__container ${fluidClass} scroll`}>
            {this.props.emptyOption && this.props.emptyOption.length > 0 ? (
              <button
                type="button"
                className={`modal-list__item ${
                  this.isValueEmpty() ? "checked" : ""
                }`}
                onClick={() => this.clearValues()}
              >
                {this.props.emptyOption}
                {this.isValueEmpty() ? (
                  <Icon name="check circle" size="small" />
                ) : null}
              </button>
            ) : null}
            {this.state.optionsToShow.map((option, id) => {
              const checked = this.isOptionChecked(option.value);
              let cls = checked ? "checked" : "";
              let icon = checked ? (
                <Icon name="check circle" size="small" />
              ) : null;

              return (
                <button
                  type="button"
                  className={`modal-list__item ${cls}`}
                  key={option.value}
                  onClick={() => this.toggleValue(option.value)}
                  onDoubleClick={this.onOptionDoubleClick}
                >
                  {option.display || option.text}
                  {icon}
                </button>
              );
            })}
          </Segment>
        );

      let content = (
        <React.Fragment>
          <Input
            fluid
            placeholder={translate("list.settings.filter")}
            onChange={value => this.handleSearchInput(value)}
            clearButton
          />
          <div style={{ textAlign: "left" }}>
            {this.props.emptyOption || this.props.hideClear ? null : (
              <Tag
                style={{ margin: "0 7px 7px 0"}}
                compact
                inline
                inverted
                icon="trash"
                onClick={() => this.clearValues()}
              >
                {tran("unique.clear")}
              </Tag>
            )}
            {this.props.multiple ? (
              <Tag
                compact
                inline
                inverted
                icon="check-square"
                onClick={() => this.addAll()}
              >
                {tran("unique.selectAll")}
              </Tag>
            ) : null}
          </div>
          {options}
        </React.Fragment>
      );

      return content;
    };

    const fluidClass = this.props.fluid ? "fluid" : "";
    const errorClass = this.props.error ? "error" : "";

    return (
      <React.Fragment>
        {this.props.trigger ? (
          <div onClick={this.toggleOpen} id={this.props.triggerId}>{this.props.trigger}</div>
        ) : (
          <div
            ref={el => (this.triggerRef = el)}
            className={`modal-list-trigger ${fluidClass} ${errorClass}`}
            onClick={this.toggleOpen} id={this.props.triggerId}
          >
            <p>{this.props.displayedValue || ""}</p>
            <Button icon="list alt" size="small" title={tran("modalList.open")} />
          </div>
        )}
        {typeof this.props.error === "string" && this.props.error.length > 0 ? (
          <span className="form-error">{this.props.error}</span>
        ) : null}
        {this.state.open ? (
          <Modal
            size="wide"
            open={true}
            header={this.props.header}
            headerIcon="list-alt"
            content={renderContent()}
            buttons={[
              {
                preset: "cancel",
                variant: "contained",
                onClick: () => this.toggleOpen(false),
                exit: true
              },
              {
                disabled: this.props.forceSelection && this.isValueEmpty(),
                content: this.props.saveButtonTxt,
                onClick: this.handleClose
              }
            ]}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

ModalList.defaultProps = {
  multiple: false,
  enableCancellation: false,
  fluidOptions: false,
  fluid: false,
  emptyOption: "",
  displayedValue: "",
  error: "",
  forceSelection: false,
  saveButtonTxt: tran("unique.save")
};

ModalList.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array
  ]),
  header: PropTypes.node,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      display: PropTypes.node,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      text: PropTypes.node
    })
  ).isRequired,
  saveButtonTxt: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  cancel: PropTypes.func,
  multiple: PropTypes.bool, // czy do wyboru jest więcej wartości
  fluidOptions: PropTypes.bool, // jedna wartość w rzędzie
  fluid: PropTypes.bool, // placeholder na całą szerokość rodzica
  emptyOption: PropTypes.string, // napis pojawiający się jako pusta wartość;
  hideClear: PropTypes.bool, // ukrywa tag "Wyczyść"
  onClose: PropTypes.func.isRequired, // zwraca wartości po zamknięciu
  onOpen: PropTypes.func,
  displayedValue: PropTypes.node, // wartość wyświetlana
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  forceSelection: PropTypes.bool, // wymusza wybór jednej z wartości -- gdy false, przycisk save ma prop disabled
  trigger: PropTypes.node, // trigger wyświetlany zamiast domyślnego
  triggerId: PropTypes.string
};

export default ModalList;

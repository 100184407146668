import { CapiClient } from "./capi/client";
import { Dictionary, DictionaryRO } from "./utils/types";
import { emptyArray, emptyObject } from "./utils/constants";
import { isStaff } from "utils/roles";

const freeze = Object.freeze;

const bootstrapConfig = (window as any).bootstrap;
const domain = bootstrapConfig.domain as string;

const devMode: boolean = bootstrapConfig.devel;

export interface BootstrapServer {
  name: string
  url: string
  client: CapiClient
}

export interface BootstrapCatalogue extends BootstrapServer {
  cat_id: string
}

const catalogues: readonly BootstrapCatalogue[] = bootstrapConfig.sowa.catalogues.map((catalogue: any) =>
  freeze({
    ...catalogue,
    // @ts-ignore
    client: freeze(new CapiClient(catalogue.url, { log: devMode ? {} : undefined }))
  })
);

const cataloguesById: Dictionary<BootstrapCatalogue> = {};

for (let i = 0; i < catalogues.length; i++)
  cataloguesById[catalogues[i].cat_id] = catalogues[i];

const cataloguesByUrl: Dictionary<BootstrapCatalogue> = {};

for (let i = 0; i < catalogues.length; i++)
  cataloguesByUrl[catalogues[i].url] = catalogues[i];

const log = devMode ? {} : undefined;

function legacyLinks(config: any) {
  const result = [];
  
  if (config.support && config.support.url)
    result.push({ "url": config.support.url, "name": "Portal wsparcia", "img": "https://static.sokrates.pl/media/support.png" })
  
  if (config.opac && config.opac.url)
    result.push({ "url": config.opac.url, "name": "OPAC", "img": "https://static.sokrates.pl/media/opac.png" })
  
  if (config.zone && config.zone.url)
    result.push({ "url": config.zone.url, "name": "Strefa Biblioteki", "img": "https://static.sokrates.pl/media/zone.png" })
  
  if (config.ipub && config.ipub.url)
    result.push({ "url": config.ipub.url, "name": "w.bibliotece.pl", "img": "https://static.sokrates.pl/media/ipub.png" })
  
  return result;
}

const title2 = bootstrapConfig.WZUW ? "System Udostępniania Dokumentów Elektronicznych" : "Zintegrowany system zarządzania biblioteką";

const acceptFiles: readonly string[] = bootstrapConfig.acceptFiles || emptyArray;
let accept = [".pdf"];
if (acceptFiles.includes("djvu"))
  accept.push(".djvu,image/x-djvu,image/x.djvu,image/vnd.djvu");
if (acceptFiles.includes("img"))
  accept.push(".png,.jpg,.webp,image/png,image/jpeg,image/webp");
if (acceptFiles.includes("epub"))
  accept.push(".epub,application/epub+zip")

let minAdminReq = bootstrapConfig.minAdminReq;
if (!isStaff(minAdminReq)) minAdminReq = "staff"; 

export default freeze({
  ...bootstrapConfig,
  title2: title2,
  links: freeze(bootstrapConfig.links || legacyLinks(bootstrapConfig)),
  textLinks: freeze(bootstrapConfig.textLinks),
  folks: freeze({
    ...bootstrapConfig.folks,
    // @ts-ignore
    client: freeze(new CapiClient(bootstrapConfig.folks.url, { log })),
    domainClient: freeze(new CapiClient(bootstrapConfig.folks.url, { log, auth: freeze([110, domain]) }))
  }),
  teka: freeze({
    ...bootstrapConfig.teka,
    client: bootstrapConfig.teka
      // @ts-ignore
      ? freeze(new CapiClient(bootstrapConfig.teka.url, { log }))
      : undefined
  }),
  kasa: freeze({
    ...bootstrapConfig.kasa,
    client: bootstrapConfig.kasa
      // @ts-ignore
      ? freeze(new CapiClient(bootstrapConfig.kasa.url, { log }))
      : undefined
  }),
  sowa: freeze({
    ...bootstrapConfig.sowa,
    numCatalogues: catalogues.length,
    firstCatalogue: catalogues[0],
    catalogues: freeze(catalogues),
    cataloguesById: freeze(cataloguesById),
    cataloguesByUrl: freeze(cataloguesByUrl)
  }),
  logo: freeze({ ...(bootstrapConfig.logo || emptyObject) }),
  devel: !!bootstrapConfig.devel,
  beta: !!bootstrapConfig.beta,
  localBeta: !bootstrapConfig.beta && !!localStorage.getItem("beta"),
  betaFlags: bootstrapConfig.betaFlags || emptyObject,
  WZUW: !!bootstrapConfig.WZUW,
  files: freeze(bootstrapConfig.files || {}),
  acceptFilesStr: bootstrapConfig.acceptFilesStr || accept.join(","),
  sisters: freeze(bootstrapConfig.sisters || []),
  minAdminReq,
  tekaNoLabels: bootstrapConfig.tekaNoLabels ?? true,
}) as any as Readonly<{
  // <!> znaczy, że to dodaje klient a nie plik konf.
  
  domain: string
  
  favicon?: string
  
  ver: string
  
  //
  // Serwery API
  //
  
  folks: Readonly<BootstrapServer> & Readonly<{
    domainClient: CapiClient
  }>
  teka: Readonly<BootstrapServer> // UWAGA: typujemy jakby zawsze ta usługa była wypełniona, ale jej propsy mogą być puste
  kasa: Readonly<BootstrapServer> // UWAGA: typujemy jakby zawsze ta usługa była wypełniona, ale jej propsy mogą być puste
  sowa: Readonly<{
    licence: number
    numCatalogues: number
    firstCatalogue: BootstrapCatalogue | undefined
    catalogues: readonly BootstrapCatalogue[]
    cataloguesById: DictionaryRO<BootstrapCatalogue | undefined>
    cataloguesByUrl: DictionaryRO<BootstrapCatalogue | undefined>
  }>
  
  //
  // Topbar
  //
  
  /** Krótka nazwa instalacji jako drugi człon tytułu dokumentu */
  name: string
  /** Długa nazwa instalacji w topbarze */
  title: string
  /** <!> Pierwszy człon tytułu dokumentu na stronie głównej i innych, które nie mają własnego */
  title2: string
  /** Adres linku pod nazwą w topbarze */
  titleUrl?: string
  /** Adres obrazu loga w topbarze i adres do którego prowadzi kliknięcie */
  logo: Readonly<{
    url?: string
    img?: string
    inv?: boolean
  }>
  /** Adres obrazu drugiego loga w topbarze i adres do którego prowadzi kliknięcie */
  secondLogo: Readonly<{
    url?: string
    img: string
    enabled?: boolean
  }>
  /** `target` linków pod logami i nazwą instalacji (domyślnie "_self")*/
  topLinkTarget?: string
  /** Instalacje siostrzane dla bibliotek z wieloma domenami */
  sisters: readonly Readonly<{
    host: string,
    name: string,
    icon?: string,
    proto?: string,
  }>[]
  
  //
  // Stopka
  //
  
  links: readonly Readonly<{
    url: string
    name: string
    img: string
  }>[]
  
  textLinks: readonly Readonly<{
    url: string
    path: string
    newTab?: boolean
  }>[]
  
  /** Czy wyświetlać wersję w stopce? */
  displayVersion?: boolean
  
  //
  //
  //
  
  opac: Readonly<{
    url: string
  }>
  
  /** Ścieżki dodatkowych plików wgranych na serwer HTTP */
  files: DictionaryRO<string>  
  
  //
  // Opcje deweloperskie
  //
  
  /** Czy włączać funkcje w fazie testów? */
  beta: boolean
  /** Czy tymczasowo włączać funkcje w fazie testów? */
  localBeta: boolean
  /** Czy włączać funkcję w fazie rozwoju i narzędzia deweloperskie? */
  devel: boolean
  /** Czy ukryć SSO przed nie-deweloperami */
  privateSSO?: boolean
  
  betaFlags: DictionaryRO<boolean>
  
  //
  // Opcje teki
  //
  
  /** Czy eksplorator ma sortować odwrotnie? (Krynica) */
  reverseExplorer?: boolean
  /** Liczba lat od daty wydania po której wyświetlać ostrzeżenie (WZUW) */
  warnOldDocs?: number
  /** Typy plików jakie Teka ma przyjmować w dialogu wyboru pliku */
  acceptFiles?: readonly string[]
  /** <Generowane> Wartość atrybutu accept dla input/file */
  acceptFilesStr: string
  /** Czy ta instalacja wyświetla stronę główną z ekspozycją dokumentów */
  tekaExpo?: boolean
  /** Czy wykopywać ludzi z czytelni po 15 min. nieaktywności */
  tekaRdrInact?: boolean
  /** Czy schować etykiety, bo to stary brzydki kod? */
  tekaNoLabels?: boolean
  /** Czy funkcję grup wyświetlać również czytelnikom? */
  groupsForAll?: boolean
  
  //
  // Ech...
  //
  
  agendaLen?: number
  
  noPrivacyPolicy?: boolean
  
  /** Minimalna rola, która ma dostęp do aplikacji Administracja */
  minAdminReq: "staff" | "senior" | "gdpr" | "admin" | "sysadmin"
  
  WZUW: boolean
  
  wzuwLinks?: readonly Readonly<{
    name: string
    url: string
    img_h: string
    img_v: string
    margin?: string
  }>[]
  
}>;

const preloadApi = document.createElement("link");
preloadApi.rel   = "preconnect";
preloadApi.as    = "fetch";
preloadApi.href  = bootstrapConfig.folks.url.match(/^.*\/\/[^\/]+/)[0];
preloadApi.crossOrigin = "";
document.head.append(preloadApi)

if (bootstrapConfig.ga4 && bootstrapConfig.ga4.id) {
  const id = bootstrapConfig.ga4.id;
  const gtag = document.createElement("script");
  gtag.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
  gtag.async = true;
  document.head.append(gtag);
  
  const w = window as any;
  w.dataLayer = w.dataLayer || [];
  w.gtag = function gtag() { w.dataLayer.push(arguments); }
  w.gtag('js', new Date());
  w.gtag('config', id, { send_page_view: false });
}

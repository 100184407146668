import React from "react";
import { emptyArrayLoading, emptyObject } from "utils/constants";
import {CapiCache} from "./hooks";
import {EnumDictionary} from "./folks";

const freeze = Object.freeze || (v => v);

const placeholderEnumValue = freeze({ key: "", desc: "<brak tłumaczenia>", order: -1 });
const placeholderEnumValueProxy = obj => freeze(new Proxy(obj, {
  get: function(target, name) {
    return target[name] || placeholderEnumValue;
  }
}));
const placeholderEnumValueProxyEmpty = placeholderEnumValueProxy(emptyObject)

const placeholderEnum = freeze({
  byKey: placeholderEnumValueProxyEmpty,
  byDesc: placeholderEnumValueProxyEmpty,
  ordered: emptyArrayLoading,
  asDropdownOptions: emptyArrayLoading,
});
const placeholderEnumCache = new Map();
const placeholderEnumProxy = obj => freeze(new Proxy(obj, {
  get: function(target, name) {
    let result = target[name];
    if (result) return result;
    result = placeholderEnumCache.get(name);
    if (result) return result;
    result = freeze({
      name,
      byKey: placeholderEnumValueProxyEmpty,
      byDesc: placeholderEnumValueProxyEmpty,
      ordered: emptyArrayLoading,
      asDropdownOptions: emptyArrayLoading,
    });
    placeholderEnumCache.set(name, result);
    return result;
  }
}));

const placeholder = freeze({ enums: placeholderEnumProxy(emptyObject), enumList: emptyArrayLoading });

export class EnumDictionaryCache extends CapiCache {
  constructor(client) {
    super(client, 2)
    this.noSession = true;
  }
  
  get(id) {
    return super.get("");
  }
  
  use(id) {
    return super.use("");
  }
  
  makeCommand = id => new EnumDictionary();
  
  objectIsLoading = id => placeholder;
  
  objectFromError = (id, err) => placeholder;
  
  objectFromResult(id, result) {
    switch (result.status) {
      case 200:
        const enums = [];
        const obj = {};
        for (let k in result.data) {
          const values = result.data[k];
          const byKey = {};
          const byDesc = {};
          const options = [];
          
          for (let i = 0; i < values.length; i++) {
            const x = values[i];
            x.order = i;
            byKey[x.key] = x;
            byDesc[x.desc] = x;
            
            const opt = {
              text: x.desc || React.createElement("code", { children: x.key }),
              value: x.key,
            }
            options.push(opt);
          }
          
          obj[k] = {
            name: k,
            byKey: placeholderEnumValueProxy(byKey),
            byDesc: placeholderEnumValueProxy(byDesc),
            ordered: freeze(values),
            asDropdownOptions: freeze(options),
          }
          enums.push(obj[k]);
        }
        return { enums: placeholderEnumProxy(obj), enumList: freeze(enums) };
      
      default:
        return this.objectFromError(id);
    }
  }
}

import { Dictionary } from "./types";

const fz = Object.freeze;

function freezeAndTagAsLoading<T extends object>(obj: T): Readonly<T> {
  Object.defineProperty(obj, "_state", {
    value: "loading",
    enumerable: false,
    writable: false,
    configurable: false
  });
  return fz(obj);
}

export const doNothing = fz(function(): void {}) as (...v: any[]) => void;

export const doNothingFluently = fz(function<T>(this: T): T { return this; }) as <T>(this: T, ...v: any[]) => T;

export const newObject = fz(() => ({} as Dictionary));

export const newArray = fz(() => [] as any[]);

export const emptyObject = fz({});

export const emptyObjectLoading = freezeAndTagAsLoading({}); // sygnalizuje, że jest pusty, bo dane się nie załadowały

export const emptyArray = fz([]) as (readonly []);

export const emptyArrayLoading = freezeAndTagAsLoading([]) as (readonly []); // sygnalizuje, że jest pusta, bo dane się nie załadowały

export const emptySet = fz(new Set<any>());

export const emptyMap = fz(new Map<any,any>());

export const getEmptyObject = fz(() => emptyObject);

export const getEmptyArray = fz(() => emptyArray);

export const identity = fz((<T>(v: T) => v) as <T>(v: T, ...args: any[]) => T);

export const increment = fz((v: number) => v + 1);

export const decrement = fz((v: number) => v - 1);

export const negate = fz((v: any) => !v);

export const alwaysNull = fz(() => null) as (...v: any[]) => null;

export const alwaysVoid = fz(() => undefined) as (...v: any[]) => undefined;

export const alwaysTrue = fz(() => true) as (...v: any[]) => true;

export const alwaysFalse = fz(() => false) as (...v: any[]) => false;

export const isTrue = fz((v: any) => !!v) as <T>(v: T) => v is Exclude<T, null | undefined | false | 0 | "">;

export const isFalse = negate;

export const asyncIdentity = fz(<T>(v: T) => Promise.resolve(v));

const ASYNC_VOID = Promise.resolve();
export const asyncVoid = fz(() => ASYNC_VOID);

export const isString = fz((v: any): v is string => typeof v === "string");

export const stateReducer = fz(<T>(state: T, action: T): T => action);


/** Służy do tworzenia krotek literałów.
 *  Tzn. `["foo", "bar"]` normalnie ma typ `string[]`,
 *  ale  `tuple("foo", "bar")`      ma typ `readonly ["foo", "bar"]`.
 *  Jest to potrzebne gdy używamy interfejsów, które używają `|` jako enumów oraz
 *  w szczególności wszelkich z `keyof X` */
export const tuple = fz(function tuple<T extends (string | number | boolean | symbol | null | undefined | object)[]>(...t: T): Readonly<T> { return fz(t) as any; });

/** Służy do tworzenia krotek literałów.
 *  Tzn. `["foo", "bar"]` normalnie ma typ `string[]`,
 *  ale  `tuple("foo", "bar")`      ma typ `["foo", "bar"]`.
 *  Jest to potrzebne gdy używamy interfejsów, które używają `|` jako enumów oraz
 *  w szczególności wszelkich z `keyof X` */
export const tupleMut = fz(function tupleMut<T extends (string | number | boolean | symbol | null | undefined | object)[]>(...t: T): T { return t });

/** Podobnie jak tuple wyżej wymusza typ literału, bo domyślnie literały stringowe mają po prostu typ `string` */
export const literal = fz(function literal<T extends string | number | boolean | symbol | null | undefined | object>(t: T) { return t; });

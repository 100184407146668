import React from "react";
import PropTypes from "prop-types";

import Icon from "../Icon";

/**
 * Komponent w pełni kontrolowanych przez komponent-rodzic (Checkbox nie przechowuje informacji o wartości)
 */
const Checkbox = props => {
  const {
    checked,
    side,
    label,
    labelStyle,
    className,
    disabled,
    required,
    ...rest
  } = props;
  
  const handleClick = e => {
    if (!props.disabled && props.onClick) {
      props.onClick();
    }
  };

  const checkedClass = checked ? "checked" : "";
  const disabledClass = disabled ? "disabled" : "";
  const sideClass = `${side}-side`;

  const _label = <label style={labelStyle}>{label} {required && <pre><Icon name="asterisk" type="error" size="tiny" /></pre>}</label>;

  return (
      <div
        className={`checkbox-wrapper ${sideClass} ${className} ${checkedClass} ${disabledClass}`}
        tabIndex={0}
        onClick={handleClick}
        {...rest}
      >
        {side === "left" ? null : _label}
        <input
          type="checkbox"
          tabIndex={-1}
          checked={checked}
          onChange={() => {}}
        />
        {side === "right" ? null : _label}
      </div>
  );
};

Checkbox.defaultProps = {
  label: "",
  className: "",
  labelStyle: {},
  disabled: false,
  side: "left",
  required: false
};

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onClick: PropTypes.func,
  labelStyle: PropTypes.object,
  disabled: PropTypes.bool,
  side: PropTypes.oneOf(["left", "right"]),
  required: PropTypes.bool
};

export default Checkbox;

import { PureComponent } from "react";
import { withRouter } from "react-router-dom";

/**
 * Wrapper dla całej aplikacji
 * Scrolluje stronę do góry po zmianie URL
 */
class ScrollToTop extends PureComponent {
  componentDidUpdate = prevProps => {
    const currentLocation = this.props.location.pathname;
    const prevLocation = prevProps.location.pathname;

    if (currentLocation !== prevLocation) {
      if (currentLocation.startsWith("/teka/") && prevLocation.startsWith("/teka/")) {
        // FIXME: hak na szybko, bo eksplorator teki ma swój kod do tego z tego co pamiętam
        return;
      }
      document.querySelector(".global-container").scrollTop = 0;
    }
  };

  render = () => {
    return this.props.children;
  };
}

export default withRouter(ScrollToTop);

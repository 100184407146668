import { isDarkTheme, getThemeByName } from "utils/themes";
import {useDerivedState} from "./hooks/derived";
import { useSettingsContext } from "./hooks/settings";

function buildResult(isContrast: boolean, themeName: string) {
  const isDark = isDarkTheme(isContrast ? "contrast" : themeName);
  return { isDark, isContrast, theme: getThemeByName(themeName) }
}

export default function useTheme() {
  const { appSettings } = useSettingsContext();
  return useDerivedState(buildResult, appSettings.contrast, appSettings.theme);
};

import { Blankholder, Button } from "components/shared";
import React from 'react';
import { useDispatch } from "react-redux";
import { LOGOUT, UPDATE_SESSION_INFO } from "sagas/types";

export function SessionOverload({ message }: { message: string }) {
  const dispatch = useDispatch();

  function logout() {
    dispatch({ type: LOGOUT });
  };

  function continueSession() {
    dispatch({ type: UPDATE_SESSION_INFO, payload: { sessionMessage: null } });
  }

  return <Blankholder icon="exclamation circle" description={message} button={
    <>
      <Button preset="logout" onClick={logout} />
      <Button preset="continue" onClick={continueSession} />
    </>
  } />
}
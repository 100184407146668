import React from "react";
import { Icon } from "components/shared";
import { useLanguage } from "utils/language";
import { useSettingsContext } from "utils/hooks/settings";

export default function ContrastSwitch() {
  const { appSettings, updateSettings } = useSettingsContext();
  const { translate } = useLanguage();

  return (
    <button
      className="main-sidebar__headerIcon"
      onClick={handleChange}
      title={translate("pages.settings.content.themes.contrast")}>
      <Icon name="adjust" />
    </button>
  );

  function handleChange() {
    updateSettings({
      contrast: !appSettings.contrast,
    });
  }
}

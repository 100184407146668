import React from "react";
import PropTypes from "prop-types";

/**
 * Komponent w pełni kontrolowanych przez komponent-rodzic (Radio nie przechowuje informacji o wartości)
 */
const Radio = props => {
  const {
    checked,
    label,
    labelStyle,
    className,
    disabled,
    onClick,
    ...rest
  } = props;

  const handleClick = () => {
    if (!disabled && onClick) {
      onClick();
    }
  };

  const checkedClass = checked ? "checked" : "";
  const disabledClass = disabled ? "disabled" : "";

  return (
      <div
        className={`radio-wrapper ${className} ${checkedClass} ${disabledClass}`}
        tabIndex={0}
        onClick={handleClick}
        {...rest}
      >
        <input
          type="radio"
          tabIndex={-1}
          checked={checked}
          onChange={() => {}}
        />
        <label style={labelStyle}>{label}</label>
      </div>
  );
};

Radio.defaultProps = {
  label: "",
  className: "",
  labelStyle: {},
  disabled: false
};

Radio.propTypes = {
  checked: PropTypes.bool.isRequired,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onClick: PropTypes.func,
  labelStyle: PropTypes.object,
  disabled: PropTypes.bool
};

export default Radio;

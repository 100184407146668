import React, { createContext, type ReactNode, useContext, useEffect } from "react";
import { emptyObject } from "utils/constants";
import { BetterContextProvider } from "utils/context";
import {
  setFont,
  setTheme,
  getFont,
  getThemeByName,
} from "utils/themes";
import { useFolksState } from "./storage";
import { useBind } from "./bind";
import { isLoaded } from "capi/hooks";
import { useDerivedState } from "utils/hooks/derived";

export const SettingsContext = createContext(emptyObject);

const STORAGE_KEY = "appSettings";

export function SettingsContextProvider({ children }: { children: ReactNode }) {
  //TODO niby odpowiedź przychodzi przed wyświetleniem się strony, ale nie wiem czy zawsze tak będzie
  const [rawSettings, setSettings, rq] = useFolksState(STORAGE_KEY, initSettings, 300);
  const settings = useDerivedState(sanitize, rawSettings);
  const updateSettings = useBind(handleUpdateSettings, settings);
  
  useEffect(() => {
    if (isLoaded(rq) && rq.status === 200 && rq.data) {
      updateSettings(JSON.parse(rq.data));
    }
  }, [isLoaded(rq)]);

  return (
    <BetterContextProvider
      context={SettingsContext}
      value={{ appSettings: settings, updateSettings }}>
      {children}
    </BetterContextProvider>
  );

  function handleUpdateSettings(oldSettings: IAppSettings, values: IAppSettings) {
    if (!values) return;

    const newSettings = { ...oldSettings, ...values };
    
    saveLocalSettings(newSettings);
    applySettings(newSettings);
    
    setSettings(Object.freeze(newSettings));
  }
}

function sanitize(settings: any): IAppSettings {
  return {
    enableShortcuts: !!settings.enableShortcuts,
    enableAnimations: !!settings.enableAnimations,
    passwordExpirationWarning: +settings.passwordExpirationWarning || 5,
    font: getFont(settings.font || "").id,
    theme: getThemeByName(settings.theme || "").id,
    contrast: !!settings.contrast,
  }
}

function applySettings(settings: IAppSettings) {
  setTheme(settings.contrast ? "contrast" : settings.theme);
  setFont(settings.font);
  setAnimations(settings.enableAnimations);
}

function saveLocalSettings(settings: IAppSettings) {
  localStorage.setItem(STORAGE_KEY, JSON.stringify(settings))
}

export function useSettingsContext() {
  return useContext(SettingsContext) as SettingsContextValue;
}

export interface IAppSettings {
  enableShortcuts: boolean;
  enableAnimations: boolean;
  passwordExpirationWarning: number;
  font: string;
  theme: string;
  contrast: boolean;
}

type Setting = keyof IAppSettings;

interface SettingsContextValue {
  appSettings: IAppSettings;
  updateSettings: (values: { [key in Setting]?: any }) => void;
}

let localSettings: any = emptyObject;
try {
  let s = localStorage.getItem(STORAGE_KEY);
  localSettings = s && JSON.parse(s) || localSettings;
}
catch (e) {
  console.error(e);
}

const initSettings: IAppSettings = {
  enableShortcuts: !!(localSettings.enableShorcuts ?? true),
  enableAnimations: !!(localSettings.enableAnimations ?? true),
  passwordExpirationWarning: +(localSettings.enableAnimations ?? 5) || 5,
  font: getFont(localSettings.font || "").id,
  theme: getThemeByName(localSettings.theme || "").id,
  contrast: !!(localSettings.contrast ?? false),
};

applySettings(initSettings);

function setAnimations(enabled: boolean) {
  if (enabled) {
    document.body.classList.add("animate");
  } else {
    document.body.classList.remove("animate");
  }
}

import React from "react";

import { Loader } from "components/shared";
import { ToastContainer } from "components/shared/Toast/ToastContainer";

import ErrorPage from "components/special-pages/ErrorPage";
import { TermsCheck } from 'components/special-pages/Terms/TermsCheck'

import { AppContainer } from "components/AppContainer";
import { TopBar } from "components/layout/TopBar";

import { translate, getLanguage } from "utils/language";

import CookiesDeclaration from "./common/CookiesDeclaration";
import { OverlayService } from "./layout/overlays";
import { BreadcrumbsService } from "utils/hooks/breadcrumbs";
import { KeyDownListenerService } from "utils/hooks/keyDownListener";
import { ShellContextProvider } from "./shell/shellContext";
import { UrlParamsContextProvider } from "utils/hooks/paramsContext";
import { SettingsContextProvider } from "utils/hooks/settings";

import { useCurrentSession, useCurrentUser } from "utils/hooks/redux";

export function App() {
  return <KeyDownListenerService>
    <SettingsContextProvider>
      <UrlParamsContextProvider>
        <OverlayService>
          <BreadcrumbsService>
            <AppContent />
          </BreadcrumbsService>
        </OverlayService>
        {TOAST_CONTAINER}
      </UrlParamsContextProvider>
    </SettingsContextProvider>
  </KeyDownListenerService>
}

function AppContent() {
  const currentUser = useCurrentUser();
  const { isUserLoggedIn, anonymousSession, sessionError } = useCurrentSession();

  const userInfo = currentUser.folks;
  const userError = currentUser.error;

  if (userError || sessionError) {
    return CONTENT_ERROR;
  }
  else if (!anonymousSession && !isUserLoggedIn) {
    return LOADING_SESSION;
  }
  else if (isUserLoggedIn && Object.keys(userInfo).length === 0) {
    return LOADING_USER;
  }
  else {
    return isUserLoggedIn ? CONTENT : CONTENT_ANONYMOUS;
  }
}


// Tutaj jako stałe w ramach optymizacji tego głównego komponentu aplikacji
const ERROR_PAGE = <ErrorPage />;
const LOADING_SESSION = <Loader text={translate("loading.sessionInitialization") + "..."} />;
const LOADING_USER = <Loader text={translate("loading.user") + "..."} />;
const CONTENT_ANONYMOUS = <>
  <div className="App" id="app">
    <ShellContextProvider>
      <AppContainer />
    </ShellContextProvider>
  </div>
  <CookiesDeclaration />
</>;
const CONTENT = <>
  {CONTENT_ANONYMOUS}
  <TermsCheck /> {/* TODO: znaleźć lepsze miejsce dla regulaminu */}
</>;
const CONTENT_ERROR = <>
  <div className="App" id="app">
    <TopBar />
    <ErrorPage />
  </div>
</>;
const TOAST_CONTAINER = <ToastContainer />;

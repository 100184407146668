import React, {PureComponent} from "react";
import PropTypes from "prop-types";

import {Icon, Popup} from "..";
import {emptyObject} from "../../../utils/constants";

import {OptionList} from "./OptionList";
import {DropdownMenu} from "./DropdownMenu";
import { getFlatTreeOptions } from './OptionListTree';

/**
 *
 * @example <Dropdown value="foo" options={[ {value: "foo", text: "Foo"}, {value: "bar", text: "Bar"} ]} />
 */
class Dropdown extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      selectedOption: null
    };
  }
  
  onClick = event => {
    event.preventDefault();
    event.stopPropagation();
    
    if (this.state.open) {
      // utrata focusa powinna zamknąć dropdown, po timeoucie, ale ech...
      // nie mam pomysłu jak to zrobić lepiej utrzymująć separację między wartswami kodu
      return;
    }
    
    this.setState({ open: true });
  };
  
  close = () => this.setState({ open: false });

  handleOptionClick = option => {
    if (option.disabled) {
      return;
    }
    
    if (option.onClick) {
      option.onClick();
    }

    this.close();

    if (this.props.onChange) {
      this.props.onChange(option.value);
    }
  };
  
  static getDerivedStateFromProps(props, state) {
    const { value, options } = props;
    let update = null;
    
    if (options !== state.options) {
      update ||= {};
      update.options = options;
      update.flatOptions = getFlatTreeOptions(options);
    }
    
    if (value !== state.value || update) {
      update ||= {};
      update.value = value;
      // nie jestem pewien po co tutaj hasOwnProperty
      update.selectedOption = (update.flatOptions || state.flatOptions)
        .find((option) => option.hasOwnProperty("value") && option.value === value) || null;
    }
    
    return update;
  }
  
  render() {
    let {
      placeholder,
      options,
      fluid,
      inline,
      disabled,
      trigger,
      error,
      className,
      menuPosition,
      simple,
      triggerPopup,
      upward,
      headerClass,
      layout,
      verticalPos,
      horizontalPos,
      variant,
      initialTreeExpanded,
      ...rest
    } = this.props;
    const { open, selectedOption } = this.state;
    
    if (layout === "tree-collapsed") {
      layout = "tree";
      initialTreeExpanded = false;
    }
    
    const hasChoice = options.length > 1 || (options.length === 1 && options[0] !== selectedOption);
    
    // TODO: może wprowadzić wizualny stan ładowania (isLoading(options))

    let _trigger = trigger ? (
      React.cloneElement(trigger, {
        ...trigger.props,
        onClick: this.onClick,
      })
    ) : (
      <button 
        type="button" 
        className={`stealth-button dropdown__header ${headerClass ? "dropdown__header--sm" : ""}`}
        disabled={disabled}
        style={hasChoice ? undefined : softDisabledStyle}
        onClick={this.onClick}>
        {selectedOption ? (
          selectedOption.text || NBSP
        ) : placeholder && placeholder.length > 0 ? (
          <span className="dropdown__placeholder">{placeholder}</span>
        ) : (
          NBSP
        )}
        {hasChoice && <Icon
          name={open ? "caret up" : "caret down"}
          className="dropdown__header-icon"
        />}
      </button>
    );

    if (triggerPopup) {
      _trigger = <Popup {...triggerPopup} trigger={_trigger} />;
    }
    
    return (
      <div style={inline ? INLINE_STYLE : fluid ? FLUID_STYLE : emptyObject}>
        <DropdownMenu
          open={open}
          options={options}
          selectedOption={selectedOption}
          variant={variant || (trigger || simple ? "free" : "attached")}
          onClose={this.close}
          onClick={this.handleOptionClick}
          layout={layout}
          verticalPos={verticalPos}
          horizontalPos={horizontalPos}
          initialTreeExpanded={initialTreeExpanded}
        >
          {ref => <div
            className={`dropdown ${className} ${
              disabled ? "disabled" : ""
            } ${open ? "open" : ""} ${error ? "error" : ""} ${
              fluid ? "fluid" : ""
            } ${trigger ? "custom-trigger" : ""} ${simple ? "simple" : ""} ${inline ? "inline" : ""}`}
            role="listbox" // FIXME: ? lista jest poza tym komponentem...
            aria-haspopup="listbox"
            aria-expanded={open ? "true" : "false"}
            ref={ref}
            {...rest}
          >
            {_trigger}
          </div>}
        </DropdownMenu>
        {error && error !== true ? (
          <div className="form-error" style={{ position: "relative", top: "-8px" }}>{error}</div> // heh, żeby było spójnie ze stylami innych błędów w formularzu
        ) : null}
      </div>
    );
  }
}

Dropdown.Menu = DropdownMenu;
Dropdown.OptionList = OptionList;

const INLINE_STYLE = Object.freeze({
  display: "inline-block",
});

const softDisabledStyle = {
  tabIndex: -1,
  pointerEvents: "none",
  touchEvents: "none",
};

const NBSP = Object.freeze(<>&nbsp;</>);

// FIXME: na kolanie robione tylko żeby fluid działało; zasadniczo error powinien być w .dropdown,
//        a zewnętrzny div nie powinien istnieć, ale wtedy błąd ląduje pomiędzy triggerem a listą opcji w overlayu
const FLUID_STYLE = Object.freeze({
  width: "100%"
})

Dropdown.defaultProps = {
  placeholder: "",
  fluid: false,
  error: "",
  className: "",
  menuPosition: "left",
  inline: false,
  simple: false,
  disabled: false,
  options: [],
  triggerPopup: null,
  upward: false,
};

Dropdown.propTypes = {
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.shape({
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.bool,
          PropTypes.number
        ]),
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
          .isRequired,
        icon: PropTypes.string,
        iconType: PropTypes.oneOf([
          "error",
          "primary",
          "success",
          "warning",
          "default"
        ]),
        disabled: PropTypes.bool,
        image: PropTypes.string, // src do img
        link: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      })
    ])
  ),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number
  ]),
  onChange: PropTypes.func,
  fluid: PropTypes.bool,
  trigger: PropTypes.node,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.bool]),
  menuPosition: PropTypes.oneOf(["left", "right", "center"]), // aktualnie nieużywane
  inline: PropTypes.bool,
  simple: PropTypes.bool,
  disabled: PropTypes.bool,
  triggerPopup: PropTypes.object, // obiekt z propsami podawanymi do Popup'a wyświetlanego dla triggera
  upward: PropTypes.bool, // menu rozwijane w górę // aktualnie nieużywane
  initialTreeExpanded: PropTypes.bool,
  variant: PropTypes.oneOf(["free", "attached"]),
  layout: PropTypes.oneOf(["tree", "tree-collapsed", "list"])
};

Dropdown.Divider = props => {
  return <div className="dropdown__divider" />;
};

export default Dropdown;

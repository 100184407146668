import React from "react";

import {
  SimpleAccordion as Accordion,
  Divider,
  Checkbox,
  Dropdown,
  ColumnView,
  Button
} from "components/shared";
import ColorPalette from "components/shared/ColorPalette";

import { FONT_LIST, getFont, getThemeByName, THEME_LIST } from "utils/themes";
import { tran } from "utils/language";
import { useSettingsContext } from "utils/hooks/settings";
import { Dialog } from "components/shared/Dialog";
import type { IDialog } from "components/shared/Dialog/useDialog";
import { useCurrentSessionLoggedIn } from "utils/hooks/redux";

export function Settings({ dialog }: { dialog: IDialog }) {
  const { updateSettings, appSettings } = useSettingsContext()
  const loggedIn = useCurrentSessionLoggedIn();

  const renderThemes = (a: number, b: number) => {
    let themePalettes = [];
    for (let i = a; i <= b; i++) {
      if(!themesList[i]) continue;

      themePalettes.push(
        <div className="themes__item">
          <ColorPalette
            animated={false}
            shadow
            tooltip={false}
            onClick={() => updateSettings({ contrast: false, theme: themesList[i].value })}
            active={
              !appSettings.contrast &&
              appSettings.theme === themesList[i].value
            }
            value={themesList[i].value}
            name={themesList[i].text}
            colors={themesList[i].props()}
          />
        </div>
      );
    }
    return themePalettes;
  };
  
  const basicThemes = renderThemes(0, 2);
  const moreThemes = renderThemes(3, themesList.length - 1);
  
  const buttons = <>
    <Button preset="close" onClick={dialog.close} />
    <Button preset="reset" onClick={() => updateSettings({
      // TODO: resetowanie powinno być wyłączone gdyby nic nie robiło, ale to wymaga inteligentnego porównania...
      enableAnimations: true,
      enableShortcuts: true,
      contrast: false,
      passwordExpirationWarning: 5,
      theme: getThemeByName("").id,
      font: getFont("").id,
    })} />
  </>

  return (
    <Dialog dialog={dialog} size="large" title={tran("pages.settings")} icon="paint-brush" buttons={buttons}>
      <ColumnView>
        {/** FIXME: jak będziemy mieli skróty klawiaturowe to tutaj wrócimy... */}
        {/*<ColumnView.Item*/}
        {/*  name={tran("pages.settings.content.shortcuts")}*/}
        {/*  value={*/}
        {/*    <Checkbox*/}
        {/*      checked={appSettings.enableShortcuts}*/}
        {/*      onClick={() => {*/}
        {/*        updateSettings({ enableShortcuts: !appSettings.enableShortcuts })*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  }*/}
        {/*/>*/}

        <ColumnView.Item
          name={tran("pages.settings.content.animations")}
          value={
            <Checkbox
              checked={appSettings.enableAnimations}
              onClick={() =>
                updateSettings({ enableAnimations: !appSettings.enableAnimations })
              }
            />
          }
        />
        
        {loggedIn && <ColumnView.Item
          name={tran("pages.settings.content.passwordExpiration.text")}
          value={
            <Dropdown
              value={appSettings.passwordExpirationWarning || 5}
              options={passwordExpirationOptions}
              onChange={value =>
                updateSettings({ passwordExpirationWarning: value })
              }
            />
          }
        />}

        <Divider />

        <ColumnView.Item
          name={tran("pages.settings.content.fontType.title")}
          value={
            <Dropdown
              placeholder="..."
              value={appSettings.font}
              options={fontList}
              onChange={value => updateSettings({ font: value })}
            />
          }
        />

        <ColumnView.Item
          name={tran("pages.settings.content.themes.contrast")}
          value={
            <Checkbox
              checked={appSettings.contrast}
              onClick={() => {
                updateSettings({ contrast: !appSettings.contrast })
              }}
            />
          }
        />

        <ColumnView.Item
          name={tran("pages.settings.content.themes.text")}
          value={null}
        />

        <div className="themes">
          <div className="themes__items">{basicThemes}</div>
          {
            moreThemes.length > 0 ? 
            <Accordion
              className="settings__themes-accordion"
              header={tran("pages.settings.content.themes.showAll")}
              headerWhileOpen={tran("unique.showLess")}
              icon="caret down"
              iconWhileOpen="caret up"
              headerPosition="bottom"
              fluid
            >
              <div className="themes__items">{moreThemes}</div>
            </Accordion> : null
          }
        </div>
      </ColumnView>
    </Dialog>
  );
};

const fontList = FONT_LIST.map(opt => {
  if (opt.id == "serif")
    return { value: opt.id, text: <i>{tran("font.serif")}</i> }
  if (opt.id == "sans-serif")
    return { value: opt.id, text: <i>{tran("font.sans-serif")}</i> }
  return { value: opt.id, text: opt.name };
});

const themesList = THEME_LIST.map(theme => ({ value: theme.id, text: theme.name, props: () => theme.colors }));

const passwordExpirationOptions = [
  {
    value: 1,
    text: tran("pages.settings.content.passwordExpiration.options.single")
  },
  {
    value: 2,
    text: <span>2 {tran("pages.settings.content.passwordExpiration.options.multiple")}</span>
  },
  {
    value: 3,
    text: <span>3 {tran("pages.settings.content.passwordExpiration.options.multiple")}</span>
  },
  {
    value: 5,
    text: <span>5 {tran("pages.settings.content.passwordExpiration.options.multiple")}</span>
  },
  {
    value: 10,
    text: <span>10 {tran("pages.settings.content.passwordExpiration.options.multiple")}</span>
  }
];
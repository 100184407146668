import { Dispatch, SetStateAction, useEffect, useState, useRef, useCallback } from "react";
import { ensure, isLoaded, notYet, useFolks } from "../../capi/hooks";
import { StorageGet, StoragePut } from "../../capi/folks";
import { useDerivedState, useForkedState } from "./derived";
import { useBind, type BoundFunction } from "./bind";
import { sessionClientByPath } from "../../capi/appclient";
import store from "../../store";
import { signalMarker } from "utils/hooks/markers";
import { debounce } from "utils/globalFunctions";
import { useCurrentSessionLoggedIn } from "utils/hooks/redux";

// skopiowane z https://github.com/microsoft/TypeScript/issues/1897#issuecomment-580962081
// TODO: przenieść w ogólnodostępne miejsce
type Json =
  | null
  | boolean
  | number
  | string
  | Json[]
  | { [prop: string]: Json | undefined };

type JsonCompatible<T> = {
  [P in keyof T]: T[P] extends Json
    ? T[P]
    : Pick<T, P> extends Required<Pick<T, P>>
      ? never
      : T[P] extends (() => any) | undefined
        ? never
        : JsonCompatible<T[P]>;
};

/** Jak useState, ale pamięta wartość JSONową pod podanym idkiem w localStorage */
export function useDeviceState<T extends JsonCompatible<T>>(id: string | undefined, initial: T | (() => T)): [T, Dispatch<SetStateAction<T>>] {
  type State = {
    id: string | undefined
    value: T
    initializer: () => T
    monitor: () => void
  }
  
  const stateRef = useRef(null as any as State);
  
  if (stateRef.current === null) {
    stateRef.current = {
      id,
      value: initial as any, // lepsze niż null bo jeśli initial to T, a T to number|bool, to JIT utworzy specjalizowany slot w obiekcie
      initializer: () => initialize(id, initial),
      monitor: () => { 
        if (stateRef.current.id)
          localStorage.setItem(stateRef.current.id, JSON.stringify(stateRef.current.value));
      }
    }
  }
  
  const state = stateRef.current;
  const hookResult = useState<T>(state.initializer);
  
  state.id = id;
  state.value = hookResult[0];
  
  // eslint-disable-next-line
  useEffect(state.monitor, hookResult);
  
  return hookResult;
}

function initialize<T extends JsonCompatible<T>>(id: string | undefined, initial: T | (() => T)): T {
  const stored = id ? localStorage.getItem(id) : null;
  
  let parsed = undefined;
  if (stored !== null)
    try {
      parsed = JSON.parse(stored);
    }
    catch (err) {
      console.log(err);
    }
  
  if (parsed === undefined)
    // @ts-ignore
    return typeof initial === "function" ? initial() : initial;
  
  return parsed;
}

// FIXME: od czasu wprowadzenia odświeżania requestów przy pomocy markerów tutaj się sieczka jakaś zrobiła z useFolksStorage/useFolksState
//        trzeba przestać używać komendy z getMarkerList i zobaczyć czy coś zostanie do poprawy

// TODO: wykombinować jakiś throttling dla ustawiania, np. asynchroniczne useSteady
// TODO: przemyśleć lokalne cache'owanie wartości w obecności błędów, zmerdżować z branchem 230

export function useFolksStorage(id: string | undefined, initial: string | (() => string) = ""): [string, (value: string) => Promise<any>, any] {
  const isUserLoggedIn = useCurrentSessionLoggedIn();
  const rq = ensure(useFolks(StorageGet, (isUserLoggedIn && id) || notYet), 200);
  const [value, setValue] = useForkedState(getValue, isLoaded(rq) ? (rq.data || "") : null, initial);
  const putValue = useBind(storagePut, isUserLoggedIn ? id : undefined).then(setValue);
  return useDerivedState(getApi, rq, value, putValue)
}

function getValue(data: string | null, initial: string | (() => string)): string {
  if (typeof data === "string")
    return data
  else
    return typeof initial === "function" ? initial() : initial;
}

function getApi(rq: any, value: string, setValue: (value: string) => Promise<any>): [string, (value: string) => Promise<any>, any] {
  return [value, setValue, rq]
}

async function storagePut(id: string | undefined, value: string) {
  if (id !== undefined) {
    const folks = sessionClientByPath(store, "folks");
    
    const [resp] = await folks.execute(new StoragePut(id, value));
    resp.ensure(204);
    signalMarker(`storage:${id}`);
  }
  
  return value;
}

const isUserLoggedInSelector = (state: any) => state.session.isUserLoggedIn;

/** Jak useState, ale jego wartość jest synchronizowana na serwerze.
 *  Wartość state zostanie zaktualizowana wartością serwerową po jej pierwszym pobraniu */
export function useFolksState<T extends JsonCompatible<T>>(
  id: string | undefined,
  initial: T | (() => T),
  delay = 1200
): [T, BoundFunction<[value: T], void>, any] {
  const isUserLoggedIn = useCurrentSessionLoggedIn();
  const [state, setState] = useState(initial);

  const initialAsString = useDerivedState(stringifyInitial, initial);
  const [storage, setStorage, rq] = useFolksStorage(id, initialAsString);

  const syncStorage = useCallback(
    debounce((s: string, v: T) => {
      const newValue = JSON.stringify(v);
      if (s !== newValue) {
        setStorage(newValue);
      }
    }, delay),
    []
  );
  const setValue = useBind(handleSetValue);

  useEffect(() => {
    if (isLoaded(rq) && rq.status === 200) {
      setState(parseValue(rq.data, initial));
    }
  }, [isLoaded(rq)]);

  function handleSetValue(value: T) {
    setState((prev) => {
      let newValue;
      if (typeof prev === "object" && typeof value === "object") {
        newValue = { ...prev, ...value };
      } else {
        newValue = value;
      }

      if (isUserLoggedIn) {
        //TODO zabezpieczyć useFolksStorage przed crashowaniem przy próbie użycia jako niezalogowany
        syncStorage(storage, newValue);
      }

      return newValue;
    });
  }

  return useDerivedState(getApi2, state, setValue, rq);
}

function stringifyInitial<T>(initial: T | (() => T)): () => string {
  // @ts-ignore
  return () => JSON.stringify(typeof initial === "function" ? initial() : initial);
}

function parseValue<T>(value: string, initial: T | (() => T)): T {
  try {
    return JSON.parse(value);
  }
  catch (e) {
    console.error(e);
    // @ts-ignore
    return typeof initial === "function" ? initial() : initial;
  }
}

function getApi2<T>(value: T, setValue: BoundFunction<[value: T], void>, rq: any): [T, BoundFunction<[value: T], void>, any] {
  return [value, setValue, rq];
}

import React from "react";
import { ensure, isReloading, useCapi } from "../../../capi/hooks";
import * as Folks from "../../../capi/folks";
import { useBind, useDerivedState } from "../../../utils/hooks";
import {
  preparePropFormInputs,
  preparePropFormModelWithMissing,
  submitUser
} from "../../pages/Admin/Users/usersReusable/userAddAndEditUtils/functions";
import { FormDialog } from "../../shared/Dialog/comps";
import { IDialog } from "../../shared/Dialog/useDialog";
import { ColumnView } from "../../shared";
import { CapiClient } from "../../../capi/client";
import { tran, useLanguage } from "../../../utils/language";
import { FOLKS_ENUMS } from "../../../capi/folksCache";
import type { FolksUserProp } from "utils/types";

type PropsDialogProps = {
  client: CapiClient
  
  // wartości istniejące zwrócone z RegistrationCheck
  propValues: {
    [name: string]: FolksUserProp
  }
  
  onSubmit: () => void
  
  dialog: IDialog
}

// ten komponent jest "stroną" w CompleteSignupDialog

export function PropsDialogPage({ propValues, client, onSubmit, dialog }: PropsDialogProps) {
  const formRq = ensure(useCapi(client, Folks.UserForm), 200);
  const folksEnums = FOLKS_ENUMS.use();
  
  const language = useLanguage();
  const form   = useDerivedState(preparePropFormModelWithMissing, formRq, folksEnums, propValues);
  const inputs = useDerivedState(preparePropFormInputs, language, form, isReloading(formRq));
  
  const submit = useBind(submitUser, client, "self", "registrant")
    .then(onSubmit);
  
  return <FormDialog
    title={tran("signup.header.props")}
    size="large" 
    name="reg-prop-form"
    savePreset="continue"
    onSubmit={submit}
    dialog={dialog}
  >
    <ColumnView>
      {inputs}
    </ColumnView>
  </FormDialog>
}

import React, { useEffect } from "react";
import { Link, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { useDerivedState } from "utils/hooks";
import { TabImpl, TabsImpl } from "./TabsImpl";
import { getSearchParams, setSearchParams } from "../../../utils/globalFunctions";
import { doNothing } from "../../../utils/constants";

export interface LinkTab extends TabImpl<string> {
  link: string
  isDefault?: boolean
}

export type LinkTabsProps = {
  type?: "primary" | "secondary" | "segment";
  center?: boolean;
  style?: React.CSSProperties;
  tabs: readonly LinkTab[];
  replaceAddress?: boolean // czy używać history.replace zamiast history.push?
  replaceInvalid?: boolean // czy zmienić adres na domyślnego taba gdy żaden nie pasuje?
}

interface LinkTabPriv extends LinkTab {
  url: string
  parts: readonly string[]
  noMatch?: boolean
  disabledMatch?: boolean
}

export function LinkTabs({ type = "primary", center = false, style, tabs, replaceAddress, replaceInvalid }: LinkTabsProps) {
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  
  const base = match.url.endsWith("/") ? match.url.substring(0, match.url.length - 1) : match.url;
  const privTabs = useDerivedState(preprocessTabs, tabs, base);
  const currentTab = useDerivedState(findTabByPath, privTabs, location.pathname);
  const tabConstructor = useDerivedState(linkTabConstructor, type, base, !!replaceAddress);
  
  useEffect((replaceInvalid && currentTab.noMatch) || currentTab.disabledMatch ? () => {
    let url = currentTab.url;
    
    // TODO: coś lepszego do manipulacji urli potrzebujemy
    const hashAt = url.indexOf("#");
    const fragment = hashAt >= 0 ? url.substring(hashAt) : location.hash;
    url = hashAt >= 0 ? url.substring(0, hashAt) : url;
    const qmarkAt = url.indexOf("?");
    const query = qmarkAt >= 0
      ? location.search
        ? setSearchParams("?", { ...getSearchParams(location.search), ...getSearchParams(url.substring(qmarkAt)) })
        : url.substring(qmarkAt)
      : location.search;
    url = qmarkAt >= 0 ? url.substring(0, qmarkAt) : url;
    
    history.replace(url + query + fragment);
  } : doNothing, [replaceInvalid, currentTab, base]);
  
  return (
    <TabsImpl<string, LinkTabPriv>
      type={type}
      center={center}
      style={style}
      tabs={privTabs}
      value={currentTab.link}
      tabConstructor={tabConstructor}
      valueFromTab={linkFromTab}
    />
  )
}

const linkTabConstructor = (type: string, base: string, replace: boolean) => (tab: LinkTabPriv, isCurrent: boolean) => (
  <Link
    role="tab"
    to={tab.url}
    tabIndex={isCurrent ? 0 : -1}
    aria-selected={isCurrent}
    className={`
      tabs__link
      tabs__link--${type}
      ${isCurrent ? "tabs__link--active" : ""}
    `}
    replace={replace}
    onDragStart={alwaysPreventDefault}
  >
    {tab.text}
  </Link>
);

function alwaysPreventDefault(ev: { preventDefault(): void }) {
  ev.preventDefault();
}

function preprocessTabs(tabs: readonly LinkTab[], base: string): readonly LinkTabPriv[] {
  // operujemy na podzielonych ścieżkach, bo to jedyny sposób na sensowną normalizację url-kodowania
  const baseParts = splitPath(base);
  return tabs.map(tab => {
    const url = absoluteLink(tab.link, base);
    return {
      ...tab, url,
      parts: splitPath(url),
    };
  })
}

function absoluteLink(link: string, base: string) {
  return link && (
    link.startsWith(".")
      ? `${base}${link.substring(1)}`
      : link.startsWith("/")
        ? link
        : `${base}/${link}`
  );
}

function splitPath(path: string) {
  const qmark = path.indexOf("?");
  if (qmark >= 0)
    path = path.substring(0, qmark);
  return path.split("/").map(decodeURIComponent);
}

// szukamy najdłuższego dopasowania
function findTabByPath(tabs: readonly LinkTabPriv[], path: string): LinkTabPriv {
  let best = 0;
  let disabledMatch = false;

  let result = null as LinkTab | null;
  
  const parts = splitPath(path);
  
  for (let i = 0, L = tabs.length; i < L; i++) {
    const tab = tabs[i];
    
    const tabParts = tab.parts;
    if (startsWith(parts, tabParts) && tabParts.length > best) {
      best = tabParts.length;
      disabledMatch = !!tab.disabled;
      result = tab.disabled ? null : tab;
    }
  }

  return result || {
    __proto__: tabs.find(tab => tab.isDefault && !tab.disabled) || tabs.find(tab => !tab.disabled) || tabs[0],
    noMatch: true, // cokolwiek zwracamy nie jest wybranym tabem
    disabledMatch, // cokolwiek zwracamy nie jest wybranym tabem, bo wybrany był wyłączony
  } as any;
}

function startsWith(longer: readonly string[], shorter: readonly string[]) {
  const S = shorter.length, L = longer.length;
  if (L < S)
    return false;
  for (let i = 0; i < S; i++) {
    if (shorter[i] !== longer[i])
      return false;
  }
  return true;
}

function linkFromTab(tab: LinkTab) {
  return tab.link;
}
